// prettier-ignore-start
import React from "react";

import { useParams }                           from "react-router-dom";

import {
    getPath,
    SystemAccessRightsAssigned,
    SystemRoleStatuses
}                                              from "../../../api/methods";
import { PATHS}                                from "../../../constants/RoutesPaths";
import SettingsSystemAccessRightValuesAssigned from "./SettingsSystemAccessRightValuesAssigned";
import { useDataRequest }                      from "../../../hooks/useDataRequest";


const SettingsSystemStatusRoleAccessRights = () => {

    const { role_status_id } = useParams();

    const roleStatus = useDataRequest({
        autoLoad    : true,
        request     : SystemRoleStatuses.getById,
        requestData : { id: role_status_id },
    });


    return <SettingsSystemAccessRightValuesAssigned
        type        = {'app.role_status'}
        title       = {<>Статус "{roleStatus.data.title ?? '-'}" / роль "{roleStatus.data.role?.title ?? '-'}" / Права доступа:</>}
        backTo      = {(roleStatus.data.id && getPath(PATHS.settings.systemStatusRoles, {status_id: roleStatus.data.status_id}))}

        getListData = {(data, success, fail) => SystemAccessRightsAssigned.getByRoleStatusId({role_status_id         }, success, fail)}
        onNew       = {(data, success, fail) => SystemAccessRightsAssigned.addToRoleStatus  ({...data, role_status_id}, success, fail)}
    />

};

export default SettingsSystemStatusRoleAccessRights;
