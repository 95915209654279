// prettier-ignore-start
import React from "react";

import {useParams} from "react-router-dom";

import { ExecutorGroup, ExecutorGroupUsers } from "../../api/methods";
import { useDataRequest }                    from "../../hooks/useDataRequest";

import { PATHS }         from "../../constants/RoutesPaths";
import LIF               from "../../components/Lists/ListItemFormats";
import LIT               from "../../components/Lists/ListItemTypes";
import ListProps         from "../../components/Lists/ListProps";
import ListFormat_2Lines from "../../components/Lists/Parts/ListFormat_2Lines";


const SettingsExecutorGroupUsersList = (props) => {

    const { group_id } = useParams();

    const executorGroupInfo = useDataRequest({
        autoLoad   : true,
        request    : ExecutorGroup.getById,
        requestData: {id: group_id},
    })

    const titleFio  = <>Фамилия Имя Отчество<br />email</>;

    const formatFio = (id, item, itemProps) => ListFormat_2Lines(getConcatItem(['surname', 'name', 'middlename'], item), item['email']);

    const getConcatItem = (names, item, separator = ' ') => {
        return names.map( (itemName) => item[itemName] ).join(separator);
    };

    const getNotAssigned = (setOptions) =>  ExecutorGroupUsers.getNotAssignedUsers({group_id}, ({data}) => {
        const optionsData = data.map(
            ({id, name, middlename, surname, email}) => {
                const title = surname+' '+name+' '+middlename+' ('+email+')';
                return { id, name: title };
            }
        )

        setOptions(optionsData);
    } )


    return <ListProps
        backLink        = {PATHS.settings.executorGroups}

        title           = {<>Исполнители в группе "{executorGroupInfo.data?.name ?? ''}"</>}
        popupPrefix     = {'executorGroupUsers'}
        name            = 'исполнитель'
        confirmName     = 'исполнители'
        isActiveColName = ''

        cols            = {[
            {name: 'is_autoassigned', title: 'Автоназначен', format: LIF.check       , type: LIT.check, canAddAndEdit: false, default: false},
            {name: 'fio'            , title: titleFio      , format: LIF.text        , type: LIT.text , canAddAndEdit: false, align: 'left', customFormatHandler: formatFio },
            {name: 'rating'         , title: 'Рейтинг'     , format: LIF.usageCounter, type: LIT.text , canAddAndEdit: false},
        ]}

        getNotAssigned  = { getNotAssigned }
        getListData     = { (data, success, fail) => ExecutorGroupUsers.getAssignedUsers({group_id}, success, fail) }
        newAll          = { (success            ) => ExecutorGroupUsers.assignAllUsers  ({group_id}, success) }
        deleteAll       = { (success            ) => ExecutorGroupUsers.deleteAllUsers  ({group_id}, success) }

        onNew           = { ({id}, success, fail) => ExecutorGroupUsers.assignUser      ({group_id, user_id: id}, success, fail) }
        onDelete        = { ({id}, success, fail) => ExecutorGroupUsers.deleteUser      ({group_id, user_id: id}, success, fail) }

        canDelete       = { () => true }
        textOnDelete    = { (item) => <>Удалить пользователя "{item.surname+' '+item.name+' '+item.middlename}" из группы?</> }
    />

    /*
            onEdit          = {({id, is_active}, success, fail) => User.updateAssignedCategory  ({user_id: userId, is_active: is_active ? 1 : 0, category_id: id}, success, fail)}

    */

};

export default SettingsExecutorGroupUsersList;