// prettier-ignore-start
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import { T }                   from "../../lang/ru";
import { Company }             from "../../api/methods";
import { PATHS }               from "../../constants/RoutesPaths";
import Form, { prepareFields } from "../../components/Form/Form";
import { fieldType, formMode } from "../../components/Form/constants";


// поля формы
const fieldsList = prepareFields({
  nameCompany             : { type: fieldType.input    , dataName: 'name'                 , text: T.nameCompany              }, // Наименование организации (сокращенное)
  fullNameCompany         : { type: fieldType.input    , dataName: 'full_name'            , text: T.fullNameCompany          }, // Наименование организации (полное)
  legalAddress            : { type: fieldType.input    , dataName: 'legal_address'        , text: T.legalAddress             }, // Юридический адрес
  mailingAddress          : { type: fieldType.input    , dataName: 'post_address'         , text: T.mailingAddress           }, // Почтовый адрес
  print_file              : { type: fieldType.fileImage, dataName: 'print_file'           , text: T.addCompanyStamp          }, // скан печати с подписью директора
  directorName            : { type: fieldType.input    , dataName: 'director_name'        , text: T.nameHeadCompany          }, // Имя директора
  directorSurname         : { type: fieldType.input    , dataName: 'director_surname'     , text: T.surnameHeadCompany       }, // Фамилия директора
  directorMiddlename      : { type: fieldType.input    , dataName: 'director_middlename'  , text: T.middlenameHeadCompany    }, // Отчество директора
  directorNameRod         : { type: fieldType.input    , dataName: 'director_name_r'      , text: T.nameHeadCompanyRod       }, // Имя директора в родительном падеже
  directorSurnameRod      : { type: fieldType.input    , dataName: 'director_surname_r'   , text: T.surnameHeadCompanyRod    }, // Фамилия директора в родительном падеже
  directorMiddlenameRod   : { type: fieldType.input    , dataName: 'director_middlename_r', text: T.middlenameHeadCompanyRod }, // Отчество директора в родительном падеже
  positionHeadCompany     : { type: fieldType.input    , dataName: 'director_position'    , text: T.positionHeadCompany      }, // должность директора
  positionHeadCompanyCase : { type: fieldType.input    , dataName: 'director_position_r'  , text: T.positionHeadCompanyCase  }, // должность директора в родительном падеже
  signature_file          : { type: fieldType.fileImage, dataName: 'signature_file'       , text: T.addSupervisorSignature   }, // скан подписи директора
  documentBasis           : { type: fieldType.input    , dataName: 'signature_doc'        , text: T.onTheDocumentBasis       }, // Действует на основании: Устава, Свидетельства, Доверенности...
  INN                     : { type: fieldType.input    , dataName: 'inn'                  , text: T.INN                      }, // ИНН
  KPP                     : { type: fieldType.input    , dataName: 'kpp'                  , text: T.KPP                      }, // КПП
  bank                    : { type: fieldType.input    , dataName: 'bank'                 , text: T.bank                     }, // Наименование банка
  rAccount                : { type: fieldType.input    , dataName: 'rAccount'             , text: T.rAccount                 }, // Расчетный счет
  kAccount                : { type: fieldType.input    , dataName: 'kAccount'             , text: T.kAccount                 }, // Корреспондентский счет
  bikBank                 : { type: fieldType.input    , dataName: 'bik'                  , text: T.bikBank                  }, // БИК банка
  phone                   : { type: fieldType.input    , dataName: 'phone'                , text: T.phone                    }, // Телефон
  ogrn                    : { type: fieldType.input    , dataName: 'ogrn'                 , text: T.OGRN                     }, // ОГРН
  email                   : { type: fieldType.input    , dataName: 'email'                , text: T.email                    }, // Электронная почта
});


// структура формы
const fieldsStruct = [
    { type: fieldType.group, text: T.enterCompanyInformation, props: {useGrid: true,  contains: [
        fieldsList.nameCompany,
        fieldsList.fullNameCompany,
        fieldsList.legalAddress,
        fieldsList.mailingAddress,
    ]} },
    fieldsList.print_file,
    { type: fieldType.group, text: T.enterInformationHeadCompany, props: {useGrid: true, contains: [
        fieldsList.directorSurname,
        fieldsList.directorSurnameRod,

        fieldsList.directorName,
        fieldsList.directorNameRod,

        fieldsList.directorMiddlename,
        fieldsList.directorMiddlenameRod,

        fieldsList.positionHeadCompany,
        fieldsList.positionHeadCompanyCase,
    ]} },
    { type: fieldType.group, props: {useGrid: false, contains: [
        fieldsList.documentBasis,
    ]} },
    fieldsList.signature_file,
    { type: fieldType.group, text: T.enterAdditionalCompanyInformation, props: {useGrid: true, contains: [
        fieldsList.INN,
        fieldsList.bank,
        fieldsList.KPP,
        fieldsList.ogrn,
        fieldsList.bikBank,
        fieldsList.rAccount,
        fieldsList.kAccount,
        fieldsList.email,
        fieldsList.phone,
    ]} },
];


const SettingsCompanyForm = ({
    mode = formMode.new, // edit || new
}) => {
    const history = useHistory();

    const { company_id } = useParams();

    return (
        <Form
            mode          = {mode}
            fieldsList    = {fieldsList}
            fieldsStruct  = {fieldsStruct}

            doGet         = {(      success, fail) => Company.get   ({         id: company_id}, success, fail)}
            doNew         = {(data, success, fail) => Company.add   (data                          , success, fail)}
            doUpdate      = {(data, success, fail) => Company.update({...data, id: company_id}, success, fail)}

            goBack        = {() => history.push(PATHS.settings.company)}

            textTitleNew  = 'Новая организация'
            textTitleEdit = 'Изменение данных организации'
            textBack      = 'список организаций'
        />
    );
}

export default SettingsCompanyForm;
