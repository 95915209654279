// prettier-ignore-start
import React from "react";

import { SystemEmails}    from "../../../api/methods";
import { useDataRequest } from "../../../hooks/useDataRequest";

import { PATHS }          from "../../../constants/RoutesPaths";
import { Preloader }      from "../../../components/UI-kit/Preloader/Preloader";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import ListFormat_2Lines  from "../../../components/Lists/Parts/ListFormat_2Lines";
import MailTestSend       from "./Components/MailTestSend";


const SettingsSystemMailsAssigned = ({
    title = '',
    types = [],
    backTo = PATHS.settings.systemStatuses,
    getListData = (d, s) => s(d),
    onNew = (d, s) => s(d),
}) => {

    const testSend = MailTestSend({
        name  : 'systemTestSendAssignedMail',
        action: SystemEmails.testSendAssigned,
    });

    const mails = useDataRequest({
        autoLoad      : true,
        request       : SystemEmails.select,
        defaultResult : [],
    });

    const formatType  = (id, item, itemProps) => types.find( (type) => type.id === item?.type_id)?.name ?? '';
    const formatMail  = (id, item, itemProps) => ListFormat_2Lines(item.mail.description, item.mail.sysname);

    const optionsType = (success) => success( types );
    const optionsMail = (success) => success( mails.data );


    if (mails.isLoading) return <Preloader />
    return ( <>
        <List
            title        = {title}
            popupPrefix  = {'systemMailsAssigned'}
            getListData  = {getListData}

            buttons      =  {[
                {type: 'group', buttons: [
                        {type: 'back', to: backTo},
                    ]},
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'id'           , title: 'id'                                 , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false},

                {name: 'is_active'    , title: 'активность'                         , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 1                 , align: 'center' },
                {name: 'type_id'      , title: 'тип'                                , format: (types.length > 1 ? LIF.linkEdit : LIF.hidden), type: LIT.select, canAddAndEdit: true , default: types[0]?.id ?? 0 , align: 'left'   , customFormatHandler: formatType, getOptions: optionsType },
                {name: 'mail_id'      , title: 'сообщение'                          , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null              , align: 'left'   , customFormatHandler: formatMail, getOptions: optionsMail },
                {name: 'is_replace_to', title: 'заменить адресат (иначе - добавить)', format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 0                 },
                {name: 'to'           , title: 'адресат'                            , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''},
                {name: 'calc_to'      , title: 'адресат'                            , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false                             , align: 'left'},
                {name: 'is_split_send', title: 'индивидуальная рассылка'            , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0                 },
                {name: 'comment'      , title: 'комментарий'                        , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''                , align: 'left'   },
            ]}

            actions = {[
                {title: 'тест', onClick: (id, item) => testSend.openForm(id, item.calc_to) },
            ]}

            onNew        = {onNew}
            onEdit       = {SystemEmails.updateAssigned}
            onDelete     = {SystemEmails.deleteAssigned}

            textOnDelete = {(item) => <>Удалить уведомление <b>{item?.mail?.sysname ?? ''}</b>?</>}
        />

        {testSend.form}
    </> )

};

export default SettingsSystemMailsAssigned;
