// prettier-ignore-start
import T                 from './types';
import { AnyAction }     from 'redux';
import { Application }   from '../../api/methods';
import {element}         from "../../models/elements.model";
import {userAnswers}     from "../../models/userAnswers.model";
import { debounce }      from '../../functions/common/common';
import questionnaireFunc from "../../functions/questionnaireFunc";
import { cloneDeep }     from 'lodash';


const initialState: userAnswers = {};
let stateForLoad: any = {};

const saveAnswersDebounced = debounce(function () {
    const application_id = document.location.pathname.split('/').pop();
    if (application_id) {
        // Application.
        // console.log(application_id, stateForLoad);
    }
}, 600);

const getElementsIds = (elements: element[], elementsIds: Set<string>) => {
    if (elements.length) {
        elements.forEach(i => {
            elementsIds.add(i.id)
            i.childrens.forEach( child => getElementsIds(child.elems, elementsIds) )
        })
    }

    return elementsIds
}


export default function userAnswersSlice(state = initialState, action: AnyAction) {
    const copyState: any = cloneDeep(state);

    switch (action.type) {
        case T.SET_USER_ANSWER:
            const { key, value, save } = action;

            stateForLoad = { ...copyState, [key]: value };
            saveAnswersDebounced();

            if (save) userAnswersSlice(stateForLoad, { type: T.SAVE_ANSWERS });

            return stateForLoad;

        case T.CLEAR_ANSWERS:
            return {}

        case T.DELETE_9_ANSWER:
            const newState: userAnswers = {};

            const elementsIds = Array.from(getElementsIds(action.elements, new Set()))
            const elementId   = action.elements[0].id;
            const baseIndex   = +elementId.split("_").at(-1)
            const count       =  elementId.split("_").length

            Object.keys(copyState).forEach( id => {
                if (elementsIds.find( i => i === id ))
                    delete copyState[id];
            } )

            Object.keys(copyState).forEach( (id) => {
                const ids                =  id.split("_");

                const answerCount        =  ids.length;
                const lastAnswerIdNumber = +ids[count - 1];
                const commonAnswerIdPart =  ids.slice(0, (count - 1)).join("_");

                let finalId = "";
                if (lastAnswerIdNumber > baseIndex && elementsIds.find(i => i.split("_")[0] === id.split("_")[0])) {
                    if (answerCount > count) { finalId = commonAnswerIdPart + "_" + (lastAnswerIdNumber - 1) + "_" + id.split("_").slice(count); }
                                        else { finalId = commonAnswerIdPart + "_" + (lastAnswerIdNumber - 1); }

                    newState[finalId] = copyState[id];
                } else {
                    newState[id]      = copyState[id];
                }
            } );

            return newState;


        case T.SET_ALL_ANSWERS:
            return { ...action.payload };

        case T.SAVE_ANSWERS:
            const application_id = document.location.pathname.split('/').pop();

            const isDemoMode = stateForLoad._notSave ?? false;

            if (! isDemoMode) {
                questionnaireFunc.saveElementsOrder(copyState);
                const userAnswer = JSON.stringify(copyState);
                Application.updateAnswer({ id: application_id, answer: userAnswer });
            }

            return copyState;

        case T.IS_IN_VIEWPORT:
            copyState.viewports[action.id] = action.inViewport;

            return copyState;

        default:
           return state;
    }
}
