import {useEffect}      from "react";
import {Application}    from "../api/methods";
import {useDataRequest} from "./useDataRequest";
import {
  AccessRightType,
  useAccessRights,
  UseAccessRightsType
}                       from "./useAccessRights";


export const useAppAccessRights: (appId: number, defaultAccessRightValue: AccessRightType) => UseAccessRightsType = (appId, defaultAccessRightValue) => {

  const appAccessRights = useDataRequest({
    request    : Application.accessRights,
    requestData: { appId }
  })

  useEffect(() => {
    if (appId) appAccessRights.load();
  }, [appId]);


  const accessRights: UseAccessRightsType = useAccessRights(appAccessRights.data, defaultAccessRightValue);


  return {
    ...accessRights
  };

};
