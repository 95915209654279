// prettier-ignore-start
import React from "react";

import { Col, Row }      from "styled-bootstrap-grid";
import { Application }   from "../../../../api/methods";
import {useDataRequest}  from "../../../../hooks/useDataRequest";

import { isProPBSystem } from "../../../../constants/SystemType";
import Checkbox          from "../../../../components/UI-kit/Checkbox";
import { CheckBoxLabel } from "../../../../components/UI-kit/CheckBoxLabel";
import FloatingSelect    from "../../../../components/UI-kit/FloatingSelect";


export const Filter = ({
    filtersInfo = {},
    onChangeCheckbox = {},
    onChangeSelect = {},
}) => {

    const users      = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterUsers        })
    const experts    = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterExpert       })
    const executors  = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterExecutor     })
    const payers     = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterPayer        })
    const categories = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterCategory     })
    const statuses   = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterStatus       })
    const apps       = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterApplications })

    const unread_chat_messages = [
        {id: 0, name: 'все заявки'},
        {id: 1, name: 'есть не прочтенные сообщения'},
    ];
    // const sources    = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterSource  })
    // const deliveries = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterDelivery  })
    // const forms      = useDataRequest({ autoLoad: true, defaultResult: [], request: Application.filterForm})


    const outFilterElement = ( title = '', name = '', options = {} ) => filtersInfo[name] && (
        <Row>
            <CheckBoxLabel style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                    name     = {name}
                    checked  = {filtersInfo[name].active}
                    onChange = {onChangeCheckbox}
                />
                &nbsp; &nbsp;
                <FloatingSelect
                    title    = {title}
                    name     = {name}
                    value    = {filtersInfo[name].value}
                    options  = {options}
                    style    = {{ margin: 0 }}
                    onChange = {onChangeSelect}
                />
            </CheckBoxLabel>
        </Row>
    )


    return (
        <Row style={{ margin: "30px 0 40px 0" }}>
            <Col col="auto" style={{ marginRight: 40 }}>
                {outFilterElement("Заявка"                         , "application_id"  , apps.data            )}
                {outFilterElement("Пользователь"                   , "user_id"         , users.data           )}
                {outFilterElement(isProPBSystem ? "ГИП" : "Эксперт", "expert_id"       , experts.data         )}
                {outFilterElement("Заказчик"                       , "payer_id"        , payers.data          )}
            </Col>
            <Col col="auto">
                {outFilterElement("Сообщения"                      , "unread_chat_msg" , unread_chat_messages )}
                {outFilterElement("Исполнитель"                    , "executor_id"     , executors.data       )}
                {outFilterElement("Статус"                         , "status_id"       , statuses.data        )}
                {outFilterElement("Категория"                      , "category_id"     , categories.data      )}
            </Col>
        </Row>
    );
};