// prettier-ignore-start
import React from "react";

import {Category, Company} from "../../api/methods";
import { T }               from "../../lang/ru";

import List from "../../components/Lists/List";
import LIF  from "../../components/Lists/ListItemFormats";
import LIT  from "../../components/Lists/ListItemTypes";


const SettingsCategories = () => {

    const optionsExecutorCompanies = (setOptions) => Company.listSelect({}, ({ data }) => setOptions(data) );


    return <List
        //title        = {T.editSources}
        popupPrefix  = {'category'}
        getListData  = {Category.getAll}
        cols         = {[
            {name: 'id'                      , title: 'id'                        , format: LIF.text},
            {name: 'name'                    , title: 'название'                  , format: LIF.linkEdit, type: LIT.text           , canAddAndEdit: true , isValid: (val) => val !== "", style:{textAlign:'left'}},
            {name: 'executor_company_id'     , title: 'Организация-исполнитель'   , format: LIF.hidden  , type: LIT.select         , canAddAndEdit: true , canEdit: true, getOptions: optionsExecutorCompanies, default: -1, isValid: () => true },
            {name: 'executor_company_name'   , title: 'Организация-исполнитель'   , format: LIF.text    , type: LIT.text           , canAddAndEdit: false },
            {name: 'postfix'                 , title: 'постфикс'                  , format: LIF.text    , type: LIT.text           , canAddAndEdit: true , isValid: (val) => val !== ""},
            {name: 'app_in_row_bonuses_count', title: 'бонусов за "заявку подряд"', format: LIF.text    , type: LIT.number_positive, canAddAndEdit: true , isValid: (val) => val >= 0  },

            {name: 'forms_count'       , title: 'форм'    , format: LIF.text},
            {name: 'applications_count', title: 'заявок'  , format: LIF.text},
        ]}
        buttons      =  {[
            {type: 'new'},
        ]}

        onNew        = {Category.add}
        onEdit       = {Category.update}
        onDelete     = {Category.delete}

        canDelete    = {(item) => (item['applications_count'] === 0) && (item['forms_count'] === 0)}

        textOnDelete = {(item) => <>{T.deleteCategory}<b>{item?.name ?? ""}</b>?</>}
    />

};

export default SettingsCategories;
