// prettier-ignore-start
import React from "react";

import { useParams } from "react-router-dom";

import { SystemAccessRightsAssigned,
    SystemRoles }                              from "../../../api/methods";
import { useDataRequest }                      from "../../../hooks/useDataRequest";
import { PATHS }                               from "../../../constants/RoutesPaths";
import SettingsSystemAccessRightValuesAssigned from "./SettingsSystemAccessRightValuesAssigned";


const SettingsSystemRoleAccessRights = () => {

    const { role_id } = useParams();

    const role = useDataRequest({
        autoLoad    : true,
        request     : SystemRoles.getById,
        requestData : { id: role_id },
    });


    return <SettingsSystemAccessRightValuesAssigned
        type        = 'role'
        title       = {<>Роль "{role.data.title ?? '-'}" / Права доступа:</>}
        backTo      = {PATHS.settings.systemRoles}
        getListData = {(data, success, fail) => SystemAccessRightsAssigned.getByRoleId({         role_id}, success, fail)}
        onNew       = {(data, success, fail) => SystemAccessRightsAssigned.addToRole  ({...data, role_id}, success, fail)}
    />

};

export default SettingsSystemRoleAccessRights;
