// prettier-ignore-start
import React from "react";
import { useHistory, useParams }    from "react-router-dom";

import {
    getPath,
    SystemRoles,
    SystemRoleStatuses,
    SystemStatuses,
}                       from "../../../api/methods";
import { reloadPage }   from "../../../functions/reloadPage";

import { PATHS }        from "../../../constants/RoutesPaths";
import List             from "../../../components/Lists/List";
import LIF              from "../../../components/Lists/ListItemFormats";
import LIT              from "../../../components/Lists/ListItemTypes";
import ListFormat_Color from "../../../components/Lists/Parts/ListFormat_Color";
import {useDataRequest} from "../../../hooks/useDataRequest";


const SettingsSystemRoleStatuses = () => {

    const history = useHistory();

    const { role_id } = useParams();

    const role = useDataRequest({
        autoLoad    : true,
        request     : SystemRoles.getById,
        requestData : { id: role_id },
    });

    const gotoRoleStatusActions      = (roleStatusId) => history.push(getPath(PATHS.settings.systemRoleStatusActions     , {role_status_id: roleStatusId}));
    const gotoRoleStatusAccessRights = (roleStatusId) => history.push(getPath(PATHS.settings.systemRoleStatusAccessRights, {role_status_id: roleStatusId}));

    const formatStatus     = (id, item, itemProps) => item?.has_parent_title ? item?.title : (item?.title +' ['+ item?.status?.title +']');
    const formatColor      = (id, item, itemProps) => ListFormat_Color(item.calc_color);
    const formatInstanceOf = (id, item, itemProps) => (! item?.instance_of) ? '' : (item?.instance_of?.role?.title ?? '') +' / '+ (item?.instance_of?.title ?? '');

    const optionsStatus     = (success) => SystemStatuses.select               ({}              , ({data}) => success(data));
    const optionsInstanceOf = (success) => SystemRoleStatuses.selectIinstanceOf({hasUnset: true}, ({data}) => success(data));

    const copyStatusRoleActions = (roleStatusId, item) => {
        if (item?.instance_of_id)
            SystemRoleStatuses.copyInstanceActions({id: roleStatusId}, ({data}) => reloadPage() );
    }


    return <List
        title        = {<>Статусы на роли "{role.data.title ?? '-'}"</>}
        popupPrefix  = {'systemRoleStatuses'}
        getListData  = {(data, success, fail) => SystemRoleStatuses.getByRoleId({role_id}, success, fail)}

        cols         = {[
          {name: 'id'                    , title: 'id'                    , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false, canEdit: false },

          {name: 'is_active'             , title: 'активность'            , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0   , align: 'center'},
          {name: 'status_id'             , title: 'статус [базовый]'      , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null, align: 'left'  , getOptions: optionsStatus, customFormatHandler: formatStatus },
          {name: 'has_parent_title'      , title: 'наследовать название'  , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 0                    },
          {name: 'title'                 , title: 'название статуса'      , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'  },
          {name: 'has_parent_description', title: 'наследовать описание'  , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 0                    },
          {name: 'description'           , title: 'описание'              , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'  },
          {name: 'is_colored'            , title: 'использовать цвет'     , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 1 },
          {name: 'has_parent_color'      , title: 'наследовать от статуса', format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: 1 },
          {name: 'color'                 , title: ''                      , format: LIF.linkEdit, type: LIT.color , canAddAndEdit: true , default: ''                   , customFormatHandler: formatColor },
          {name: 'instance_of_id'        , title: 'копия от'              , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null, align: 'left'  , getOptions: optionsInstanceOf, customFormatHandler: formatInstanceOf },
          {name: 'access_rights_count'   , title: 'прав'                  , format: LIF.usageCounter},
          {name: 'instance_to_count'     , title: 'ссыл'                  , format: LIF.usageCounter},
          {name: 'actions_count'         , title: 'дйст'                  , format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'действия'  , onClick: gotoRoleStatusActions     , canDo: () => true },
            {title: 'права'     , onClick: gotoRoleStatusAccessRights, canDo: () => true },
            {title: 'копировать', onClick: copyStatusRoleActions     , canDo: (item) => (!! item?.instance_of_id)},
        ]}
        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: PATHS.settings.systemRoles},
            ]},
            {type: 'new'},
        ]}

        onNew        = {(data, s, f) => SystemRoleStatuses.add({...data, role_id}, s, f)   }
        onEdit       = {SystemRoleStatuses.update}
        onDelete     = {SystemRoleStatuses.delete}

        canDelete    = {(item) => (item['actions_count'] === 0) && (item['instance_to_count'] === 0) && (item['access_rights_count'] === 0)}

        textOnDelete = {(item) => <>Удалить статус <b>{item?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemRoleStatuses;
