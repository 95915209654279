// prettier-ignore-start
import React, { useEffect, useState } from 'react';

import { useHistory, useParams }    from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row }                 from 'styled-bootstrap-grid';
import ym                           from 'react-yandex-metrika';
import ReactGA                      from 'react-ga';

import { Application, Delivery, User, Payers,
    Promocode, getPath } from '../../../api/methods';
import { isValidEmailCheck,
     isValidPhoneCheck } from '../../../functions/validators';
import { isEmptyString } from "../../../functions/common/common";
import { useSaving }     from "../../../hooks/useSaving";
import { T }             from '../../../lang/ru';
import { setActiveStep } from '../../../store/activeStep/actions';


import checkSuccessImg   from '../../../assets/svg/check.svg';
import checkFailImg      from '../../../assets/svg/close.svg';

import { PATHS }         from '../../../constants/RoutesPaths';

import FloatingSelect    from '../../../components/UI-kit/FloatingSelect';
import Checkbox          from '../../../components/UI-kit/Checkbox';
import { CheckBoxLabel } from '../../../components/UI-kit/CheckBoxLabel';
import Button            from '../../../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../../../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput     from '../../../components/UI-kit/FloatingInput';
import InputCheckbox     from '../../../components/UI-kit/InputCheckbox';
import Radio             from '../../../components/UI-kit/Radio';
import Subtitle2         from '../../../components/UI-kit/Subtitle2';
import Title             from '../../../components/UI-kit/Title';


const CheckSuccess = () => <img src={checkSuccessImg} alt="" style={{width:16, height:16}}/>;
const CheckFail    = () => <img src={checkFailImg   } alt="" style={{width:16, height:16}}/>;

const Check        = (isFail) => {
    if (isFail === null) return null;
    if (isFail         ) return null; // CheckFail();
                         return CheckSuccess();
}

const OrderNewSt5Delivery = () => {

  const { payer_id, category_id, form_id, application_id } = useParams();

  const dispatch      = useDispatch();
  const history       = useHistory ();
  const bonusesStatus = useSelector(state => state.bonuses?.status)
  const promoChecking = useSaving('', '', '');
  const saving        = useSaving();

  const [isNeedDelivery  , setIsNeedDelivery  ] = useState('true' );
  const [useBonuses      , setUseBonuses      ] = useState('false')
  const [wrongPromo      , setWrongPromo      ] = useState(null )
  const [allDeliveries   , setlAllDeliveries  ] = useState([]   );
  const [payerType       , setPayerType       ] = useState(null );
  const [isAgree         , setIsAgree         ] = useState(false);

  const [values          , setValues          ] = useState({
      adress                : '',
      deliveryAddressMatches: false,
      promo                 : '',
      contact_name          : '',
      contact_phone         : '',
      contact_email         : '',
      wishes                : '',
      bonuses               : 0,
      delivery              : -1,
  });

  const {
      adress,
      deliveryAddressMatches,
      promo,
      contact_name,
      contact_phone,
      contact_email,
      wishes,
      delivery,
      bonuses,
  } = values;

  const toggleIsAgree = () => setIsAgree(!isAgree);

  const isValidPromo = (wrongPromo === null) || (! wrongPromo) || isEmptyString(values.promo);

  useEffect(() => {
      dispatch(setActiveStep(3));
      getAllDeliveries();
      loadCustomerInfo();
      loadCurrentUserInfo();
  }, []);

  const useBonusesBool     = useBonuses     === 'true';
  const isNeedDeliveryBool = isNeedDelivery === 'true';

  useEffect(() => {
      if (useBonusesBool  && (values.bonuses === 0))
          values.bonuses = bonusesStatus.bonuses_count ?? 0;
  }, [useBonuses]);


  const loadCustomerInfo = () => Payers.getById({ id: payer_id }, ({ data }) => {
      if (data.length > 0) setPayerType(data[0].type);
  });

  const loadCurrentUserInfo = () => {
      User.getCurrent({}, ({ data }) => {
          setValues((state) => {
              return {
                  ...state,
                  contact_name: data.surname + ' ' + data.name + ' ' + data.middlename,
                  contact_phone: data.phone,
                  contact_email: data.email,
                  bonuses: 0,
              };
          });
      });
  };

  const getAllDeliveries = () => Delivery.getAll({}, sucessGetAllDelivery);

  const sucessGetAllDelivery = ({ data }) => setlAllDeliveries(data);

  const onChange = (e) => {
      let { value, name, type, checked } = e.target;

      if (type === 'checkbox') value = checked;

      checkValid(e);

      if (name === 'bonuses' && value.match(/^[0-9]*$/)) {
          if (value > bonusesStatus?.bonuses_count) {
              setValues((state) => ({ ...state, [name]: +bonusesStatus?.bonuses_count }) );
          } else {
              setValues((state) => ({ ...state, [name]: +value }) );
          }
      } else {
          setValues((state) => ({ ...state, [name]: value }) );
      }
  };

  const [isValid, setIsValid] = useState({
      contact_phone: true,
      contact_email: true,
  });

  const checkValid = (e) => {
      const { value, name } = e.target;
      const validator = validators[name];

      if (validator) setIsValid({ ...isValid, [name]: validator(value) });
  };

  const validators = {
      contact_email: (value) => isValidEmailCheck(value) || !value,
      contact_phone: (value) => isValidPhoneCheck(value) || !value,
  };

  const sendData = () => {
      dispatch(setActiveStep(4));
      // const userAnswers = localStorage.getItem('user_answers');

      saving.activate();

      const data = {
          id                  : application_id,
      //    answer              : userAnswers,
          is_needed           : isNeedDeliveryBool ? 1 : 0,
          delivery_method_id  : delivery,
          address             : adress,
          contact_name        : contact_name,
          contact_phone       : contact_phone,
          contact_email       : contact_email,
          is_adress_from_payer: deliveryAddressMatches ? 1 : 0,
          promocode           : promo,
          bonuses_to_use      : useBonusesBool ? bonuses : 0,
          wishes,
      };

      Application.finish(data, sendApplicationSuccess);
  }

  const checkPromo = (success = () => {}, fail = () => {}) => {
      if (isEmptyString(values.promo)) {
          setWrongPromo(null);
          promoChecking.deactivate();
      } else {
          promoChecking.activate();
          Promocode.isExist({name: values.promo}, () => {
              setWrongPromo(false);
              promoChecking.deactivate();
              success();
          }, () => {
              setWrongPromo(true);
              promoChecking.deactivate();
              fail();
          })
      }
  }

  const onClickSend = () => {
      if (isValidPromo) sendData();
  };

  const sendApplicationSuccess = (res) => {
      ym('reachGoal', 'send1');
      ReactGA.ga('send', 'event', 'send1', 'submit');

      saving.deactivate();

      history.push(PATHS.orderNewSt6Success);
  };

  const goToRequestCategories = () => history.push(
      getPath( PATHS.orderNewSt4Form, {payer_id, category_id, form_id, application_id})
  );

  const isDisabledSubmitButton = () => ( !isAgree ||  saving.isActive || promoChecking.isActive || Object.values(isValid).includes(false) || (! isValidPromo) );


  return <>
      <Title>{T.filterNames.delivery}</Title>
      <Subtitle2>{T.needDelivery}</Subtitle2>

      <Radio
          radios     = {T.isNeedDeliveryRadio}
          nameRadio  = "needDelivery"
          setValue   = {setIsNeedDelivery}
          radioValue = {isNeedDelivery}
      />

      {isNeedDeliveryBool && <>
          <Subtitle2>{T.chooseTypeDelivery}</Subtitle2>
          <FloatingSelect
              title    = {T.deliveryMethod}
              value    = {delivery}
              name     = "delivery"
              onChange = {onChange}
              options  = {allDeliveries}
          />
          <Row>
              <Col col="auto">
                  <FloatingInput
                      placeholder = {T.adress}
                      name        = "adress"
                      value       = {adress}
                      onChange    = {onChange}
                      disabled    = {deliveryAddressMatches}
                  />
              </Col>
              <Col col="auto" style={{ display: 'flex', alignItems: 'center' }}>
                  <InputCheckbox
                      name        = "deliveryAddressMatches"
                      placeholder = {
                          payerType == null
                              ? T.deliveryAddressMatchesPhis
                              : payerType === 1
                                  ? T.deliveryAddressMatchesPhis
                                  : T.deliveryAddressMatches
                      }
                      onChange    = {onChange}
                      checked     = {deliveryAddressMatches}
                  />
              </Col>
          </Row>
      </>}

      <Subtitle2>{T.deliveryContact}</Subtitle2>
      <FloatingInput
          placeholder  = {T.deliveryContactName}
          value        = {contact_name}
          name         = "contact_name"
          onChange     = {onChange}
      />
      <FloatingInput
          placeholder  = {T.deliveryContactPhone}
          value        = {contact_phone}
          name         = "contact_phone"
          onChange     = {onChange}
          wrong        = {!isValid.contact_phone}
          wrongMessage = {T.inCorrectPhone}
      />
      <FloatingInput
          placeholder  = {T.deliveryContactEmail}
          value        = {contact_email}
          name         = "contact_email"
          onChange     = {onChange}
          wrong        = {!isValid.contact_email}
          wrongMessage = {T.inCorrectEmail}
      />

      {bonusesStatus?.is_bonus_program_member && <div style={{display: "flex"}}>
          <Subtitle2>{T.deliveryBonuses}</Subtitle2>
          <Radio
              disabled   = {bonusesStatus?.bonuses_count === 0}
              radios     = {T.bonuses}
              nameRadio  = "useBonuses"
              setValue   = {setUseBonuses}
              radioValue = {useBonuses}
              margin     = "20px 0 0"
          />
          {useBonusesBool && <FloatingInput
              // style={{margin: 0}}
              placeholder = {"Сумма бонусов"}
              value       = {values.bonuses}
              name        = "bonuses"
              onChange    = {onChange}
          />}
      </div>}

      <Subtitle2>{T.youHavePromo}</Subtitle2>
      <Row style={{ display: 'flex', alignItems: 'center'}}>
          <Col col="auto">
              <FloatingInput
                  placeholder  = {T.promo}
                  value        = {promo}
                  name         = "promo"
                  wrong        = {wrongPromo}
                  wrongMessage = "Неверно введен промокод"
                  onChange     = {onChange}
                  onBlur       = {() => checkPromo()}
              />
          </Col>
          <Col col="auto" style={{paddingTop: '10px'}}>
              {promoChecking.isActive ? promoChecking.spinner() : Check(wrongPromo)}
          </Col>
      </Row>

      <Subtitle2>{T.yourWishes}</Subtitle2>
      <FloatingInput
          isTextarea
          value    = {wishes}
          name     = "wishes"
          onChange = {onChange}
          width    = "500px"
      />

      <Subtitle2>{T.overall}</Subtitle2>

      <CheckBoxLabel
          style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
      >
          <Checkbox
              // onClick={toggleIsAgree}
              checked  = {isAgree}
              onChange = {toggleIsAgree}
          />
          <span style={{ marginLeft: 13, fontSize: 12, lineHeight: '200%' }}>
              Нажимая кнопку «Отправить» я соглашаюсь с обработкой моих персональных
              данных и подтверждаю осведомленность о недопустимости размещения в
              личном кабинете сервиса «Фабрика Расчетов» документов, сведений или
              информации, относящихся к государственной, коммерческой, служебной и
              (или) иной охраняемой законом тайне, а также персональных данных
              других физических лиц в соответствии с Федеральным законом «О
              персональных данных» от 27.07.2006 № 152-ФЗ.
          </span>
      </CheckBoxLabel>

      <Button disabled={isDisabledSubmitButton()} onClick={onClickSend}>
          {saving.isActive ? saving.spinner() : T.send}
      </Button>

      {(! saving.isActive) && <SecondOrderButton marginLeft onClick={goToRequestCategories}>
          {T.back}
      </SecondOrderButton>}
  </>

};

export default OrderNewSt5Delivery;
