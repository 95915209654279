// prettier-ignore-start
import React from "react";

import { useHistory }       from "react-router-dom";

import { Company, getPath } from "../../api/methods";
import { PATHS }            from "../../constants/RoutesPaths";
import LIF                  from "../../components/Lists/ListItemFormats";
import List                 from "../../components/Lists/List";


const SettingsCompanyList = () => {

    const history = useHistory();

    const gotoEdit = (id) => history.push(getPath(PATHS.settings.companyEdit, {company_id: id}));


    return <List
        popupPrefix  = {'companyList'}
        getListData  = {Company.list}
        cols         = {[
          {name: 'id'  , title: 'id'      , format: LIF.text},
          {name: 'name', title: 'название', format: LIF.linkGoTo, canAddAndEdit: false, align: 'left', props: {goTo: gotoEdit} },
          {name: 'inn' , title: 'ИНН'     , format: LIF.text    , canAddAndEdit: false },

          {name: 'categories_count'  , title: 'категорий', format: LIF.usageCounter},
          {name: 'applications_count', title: 'заявок'   , format: LIF.usageCounter},
        ]}
        buttons      =  {[
            {title: '+', onClick: () => history.push(PATHS.settings.companyNew)},
        ]}

        onDelete     = {Company.delete}

        canDelete    = {(item) => (item['applications_count'] === 0) && (item['categories_count'] === 0)}

        textOnDelete = {(item) => <>Удалить организацию <b>{item?.name ?? ""}</b>?</>}
    />

};

export default SettingsCompanyList;
