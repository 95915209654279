// prettier-ignore-start
import React, { useEffect, useState } from 'react';

import { useSelector }                from 'react-redux';
import {useHistory}                   from 'react-router-dom';
import * as Scroll                    from 'react-scroll';
import ym                             from 'react-yandex-metrika';
import ReactGA                        from 'react-ga';
import { T }                          from 'lang/ru';

import { Application, Element, Form } from '../../../api/methods';
import { setElements, clearElements,
   modificateIdsOfSuperComplexLists } from '../../../store/elements/actions';
import { setActiveStep }              from '../../../store/activeStep/actions';
import {clearAnswers, saveAnswers,
                      setAllAnswers } from '../../../store/userAnswers/actions';
import { previewModeON }              from '../../../store/previewMode/actions';
import { getUserAnswers }             from '../../../store/userAnswers/selectors';
import { setEmptyFields }             from '../../../store/emptyFieldsIds/actions';
import { autosaveSet }                from "../../../store/autosave/actions";

import { element, systemName }        from '../../../models/elements.model';

import questionnaireFunc              from '../../../functions/questionnaireFunc';
import { isEmpty, isNotEmpty }        from "../../../functions/common";

import useAppDispatch                 from '../../../hooks/useAppDispatch';
import useAppSelector                 from '../../../hooks/useAppSelector';

import PullElements                   from '../Editor/PullElements';
import Button                         from '../../../components/UI-kit/Buttons/Button';
import Subtitle2                      from '../../../components/UI-kit/Subtitle2';
import SecondOrderButton              from '../../../components/UI-kit/Buttons/SecondOrderButton';
import FindEmptyField                 from '../../../components/UI-kit/FindEmptyField';
import {useActive}                    from "../../../hooks/useActive";
import {FullscreenPreloader}          from "../../../components/UI-kit/Preloader/FullscreenPreloader";


type propsType = {
    isPreview       : boolean,
    autosave        : boolean,

    form_id         : string,
    application_id? : string,

    nextTitle?      : string,
    nextTo?         : string,

    backTitle?      : string,
    backTo?         : string,
};

type options = {
    id  : number;
    name: string;
};


const Questionnaire: React.FC<propsType> = ({
    isPreview= true,
    autosave = true,

    form_id = '',
    application_id = null,

    nextTitle = 'Далее',
    nextTo = '',

    backTitle = T.back,
    backTo = '',
}) => {

    const history     = useHistory();
    const dispatch    = useAppDispatch();
    const elements    = useAppSelector((state) => state.elements);
    const userAnswers = useSelector(getUserAnswers);
    const loading     = useActive(true);

    const hasNotSave  = isEmpty(application_id) || (userAnswers['_notSave'] ?? false);
    const hasNext     = (! isPreview) && (! hasNotSave);


    const [systemNames       , setSystemNames       ] = useState<systemName[]>([]);
    const [systemNamesOptions, setSystemNamesOptions] = useState<options[]>([]);

    useEffect(() => {
        loading.activate();

        loadForm(form_id);

        enablePreviewMode();
        loadSystemNames();

        dispatch(setActiveStep(2));
        dispatch(setEmptyFields([]))
        dispatch(autosaveSet(autosave));
    }, []);

    const loadSystemNames = () => Element.getSystemNames({}, loadSystemNamesSuccess);

    const loadSystemNamesSuccess = ({ data }: { data: systemName[] }) => {
        const options: options[] = data.map(({ id, title }) => ({ id, name: title }) );

        setSystemNamesOptions(options);
        setSystemNames(data);
    };

    const loadForm = (id: string) => Form.getById({ id }, loadFormSuccess);

    const loadFormSuccess = ({ data }: { data: element[] }) => {
        setElementsToStore(data);
        modificateComplexLists();
        loadUserAnswers();
    };

    const enablePreviewMode      = () => dispatch(previewModeON());
    const modificateComplexLists = () => dispatch(modificateIdsOfSuperComplexLists());

    const setElementsToStore     = (elements: element[]) => dispatch(setElements(elements));

    const loadUserAnswers = () => {
        if (application_id) {
            Application.getAnswer({ id: application_id }, loadUserAnswersSuccess);
        } else {
            loading.deactivate();

            dispatch(setAllAnswers({ _notSave: true }));
        }
    };

    const loadUserAnswersSuccess = ({ data }: { data: { answer: string } }) => {
        const answer = JSON.parse(data.answer);

        dispatch(setAllAnswers(answer));

        loading.deactivate();
    };

    const handleBackButton = () => {
        dispatch(clearElements())
        dispatch(clearAnswers())

        if (isEmpty(backTo)) {
            history.goBack();
        } else {
            history.push(backTo);
        }
    };

    const handleNextButton = () => {
        if (! hasNext) return;

        const requiredEmptyFieldsIds = questionnaireFunc.getRequiredEmptyFieldsIds(
            elements,
            userAnswers
        );

        if (requiredEmptyFieldsIds.length) {
            alert('Вы заполнили не все обязательные поля!');
            dispatch(setEmptyFields(requiredEmptyFieldsIds));
            Scroll.scroller.scrollTo(requiredEmptyFieldsIds[0], { smooth: true });

            return;
        }
        if (! isPreview) dispatch(saveAnswers());

        // localStorage.setItem('user_answers', JSON.stringify(userAnswers));

        ym('reachGoal', 'farther1');
        ReactGA.ga('send', 'event', 'farther1', 'submit');

        history.push(nextTo);
    };


    if (loading.isActive) return <FullscreenPreloader />
    return <>
        <Subtitle2 style={{ marginTop: 30, marginBottom: 0 }}>
            Поля отмечененные символом звездочка (*) обязательны для заполнения
        </Subtitle2>

        <PullElements
            systemNames = {systemNamesOptions}
            elements    = {elements}
        />

        <FindEmptyField />

        <br />

        { isNotEmpty(backTitle) &&
            <SecondOrderButton marginRight onClick={handleBackButton}>{backTitle}</SecondOrderButton>}

        {(isNotEmpty(nextTo) && isNotEmpty(nextTitle) && hasNext) &&
            <Button onClick={handleNextButton}>{nextTitle}</Button>}
    </>

};

export default Questionnaire;
