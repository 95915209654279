import axios from 'axios';
import { PATHS } from '../constants/RoutesPaths';
import store from 'store/index';
import { setLoaderState } from '../store/loader/actions';

// prettier-ignore-start

// const baseUrl = "http://ndevelop.ru";
// const baseUrl = "http://new.fabras.ru"
export const API_URL: string | undefined = process.env.REACT_APP_API_URL;

export type AnyJson = boolean | number | string | null | JsonArray | JsonObject;
interface JsonObject {
  [key: string]: AnyJson;
}
interface JsonArray extends Array<AnyJson> {}
type ArgType = any; //string | boolean | number;

const popDataKey = (data: DataArgs, key: string) => {
  const value = data[key] ?? null;
  delete data[key];
  return value;
}

type DataArgs = {
  [key: string]: ArgType;
};

type SuccessType = (res: any) => void;
type ErrorType = (err: any) => void;
type loadType = (event: any) => void;

type MethodType = {
  [key: string]: (
    data?: DataArgs,
    success?: SuccessType,
    fail?: ErrorType,
    load?: loadType,
  ) => void;
};

const convertJSONToUrlEncoded: (obj: any) => string = (obj) => {
  var str = [];
  for (var key in obj) {
    if (key === "id") continue;
    if (obj.hasOwnProperty(key)) {
      str.push(key + "=" + obj[key]);
    }
  }
  return str.join("&");
};

const goToLogin = () => {
  document.location.replace(PATHS.login);
};

export const getPath = (path: string, params: DataArgs) => {
  for (let key in params) {
    let param = ':'.concat(key);
    path = path.replace(param, String(params[key]));
  }
  return path; // для history.push(path);
}

const successCallback = (res: AnyJson, success: any) => {
  store.dispatch(setLoaderState("SUCCESS"));
  setTimeout(() => store.dispatch(setLoaderState("DEFAULT")), 2000);
  success(res);
};

const failFunction = (res: any, fail: any) => {
  store.dispatch(setLoaderState("ERROR"));
  setTimeout(() => store.dispatch(setLoaderState("DEFAULT")), 2000);
  fail(res);
};
//  все касатемое рефреша токена
let isRefreshProcessing = false;

// Список зафейленных функций от атворизации для выполнения их после рефреша токена
let failedByAuthorizationFunctions: any = [];

const getToken = (token: any) => "Bearer " + token;

const commonRequest = (
  methodClass: string,
  requestType:
    | "POST"
    | "GET"
    | "PUT"
    | "DELETE"
    | "get"
    | "delete"
    | "head"
    | "HEAD"
    | "options"
    | "OPTIONS"
    | "post"
    | "put"
    | "patch"
    | "PATCH"
    | "link"
    | "LINK"
    | "unlink"
    | "UNLINK"
    | undefined,
  method: string,
  data: DataArgs,
  success: SuccessType = () => {},
  fail: ErrorType = () => {},
  options: any = {}
) => {
  const isDataEmpty = Object.keys(data).length === 0;

  type TokenType = string | null;
  let token: TokenType = localStorage.getItem("accessToken");
  let response: (token: TokenType) => any;

  // store.dispatch(setLoaderState("LOADING"));

  let formData = new FormData();

  let isHaveFile = false;

  for (const key in data) {
    if (key.includes("file")) isHaveFile = true;
    formData.append(key, data[key]);
  }

  const headers: any = {
    "Content-Type":
      requestType === "PUT" ? "x-www-form-urlencoded" : "multipart/form-data",
  };

  if (token) {
    headers.Authorization = getToken(token);
  }

  const responseType: "blob" | "json" = isHaveFile ? "blob" : "json";

  const config = {
    headers,
    responseType,
    ...options,
  };

  if (method !== "") {
    method = "/" + method;
  }

  let urlEncodedData = "";

  if (! isDataEmpty) {
    urlEncodedData = "?" + convertJSONToUrlEncoded(data);
  }

  const replaceToken = (config: any, token: any) => {
    config.headers.Authorization = getToken(token);
  };

  if (requestType === "GET")
    response = (token) => {
      replaceToken(config, token);
      return axios.get(API_URL + methodClass + method + urlEncodedData, config);
    };
  else if (requestType === "DELETE")
    response = (token) => {
      replaceToken(config, token);
      return axios.delete(API_URL + methodClass + method, config);
    };
  else if (requestType === "POST")
    response = (token) => {
      replaceToken(config, token);
      return axios({
        method : requestType,
        baseURL: API_URL,
        url    : methodClass + method,
        headers,
        data   : formData,

        ...options,
      });
    };
  else if (requestType === "PUT")
    response = (token) => {
      replaceToken(config, token);
      return axios.put(
        API_URL + methodClass + method + urlEncodedData,
        {},
        config
      );
    };
  else {
    response = (token) => {
      headers.Authorization = getToken(token);
      return axios({
        method : requestType,
        baseURL: API_URL,
        url    : methodClass + method,
        headers,
        data   : formData,
        ...options,
      });
    };
  }

  response(token)
    .then ((res: any    ) => {
      return successCallback(res, success)
    })
    .catch((res: any    ) => { //res: AnyJson
      const token = localStorage.getItem("accessToken");
      const isAuthError = JSON.stringify(res).includes("401");

      // если 401 и это не обнова токена
      if (isAuthError) {
        const isRefreshMethod = method.includes("refresh");

        if (isRefreshMethod) {
          failFunction(res, fail);
          return;
        }

        failedByAuthorizationFunctions.push({ response, success });

        if (! isRefreshProcessing) {
          isRefreshProcessing = true;

          Auth.refreshToken(
            { refreshToken: token ? token : "" },
            ({ data }) => {
              const { access_token } = data;

              localStorage.setItem("accessToken", access_token);

              isRefreshProcessing = false;

              failedByAuthorizationFunctions.map(
                ({ response, success }: any) => {
                  response(access_token).then((res: any) =>
                    successCallback(res, success)
                  );
                }
              );
              failedByAuthorizationFunctions = [];
            },
            () => {
              goToLogin();
              localStorage.clear();
            }
          );
        }
      } else {
        failFunction(res, fail);
      }
    });
};

export const optionsResponseBlob = () => ( { responseType: "blob" } );

export const optionsUpload   = (load: any) => (typeof load !== "undefined") ? { onUploadProgress  : load } : {} ;
export const optionsDownload = (load: any) => (typeof load !== "undefined") ? { onDownloadProgress: load } : {} ;

export const optionsDownloadBlob = (load: any) => ({ ...optionsResponseBlob(), ...optionsDownload(load) });

export const getRequestOptions = (data: DataArgs) => {
  return {
    id  : popDataKey(data, 'id'),
    type: popDataKey(data, 'type'),
    options: {}
  }
}

export const getRequestDownloadOptions = (data: DataArgs, load: any) => {
  return {
    ...getRequestOptions(data),
    options: optionsDownloadBlob(load)
  }
}

export const getRequestUploadOptions = (data: DataArgs, load: any) => {
  return {
    ...getRequestOptions(data),
    options: optionsUpload(load)
  }
}

const prepareDataBoolean: (data: DataArgs, keys: string[]) => DataArgs = (data, keys) => {
  keys.map( (key: string) => {
    if (key in data) data = {...data, [key]: (data[key] ? '1' : '0')}
  });

  return data;
}

const prepareDataIsActive: (data: DataArgs, keys?: string[]) => DataArgs = (data, keys ) =>
    prepareDataBoolean(data, (keys ? [...keys, 'is_active'] : ['is_active']) );

const CLASS = {
  auth             : "auth",
  category         : "category",
  form             : "form",
  chain            : "chain",
  element          : "element",
  applications     : "applications",
  payers           : "payers",
  message          : "message",
  company          : "company",
  settings         : 'settings',
  user             : "user",
  bonuses          : "user/bonus",
  bonusesProgram   : "bonus_program",
  executorGroup    : 'executorgroup',
  delivery         : "deliverymethods",
  source           : "source",
  promocode        : "promocode",
  software_product : 'software_product',
  system           : 'system',
  rating           : 'rating',
};

export const SoftwareProduct: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.software_product, "GET", "", {}, success, fail),

  getAllSimple: (data = {}, success, fail) =>
    commonRequest(CLASS.software_product, "GET", "simple", {}, success, fail),

  add: (data = { is_active: '', name: '', order_id: '' }, success, fail) =>
    commonRequest(CLASS.software_product, "POST", "", data, success, fail),

  update: (data = { id: '', is_active: '', name: '', order_id: '' }, success, fail) =>
    commonRequest(CLASS.software_product, "PUT", data.id, data, success, fail),

  delete: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.software_product, "DELETE", data.id, {}, success, fail),
};

export const ExecutorGroup: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", "", {}, success, fail),

  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", data.id, {}, success, fail),

  getAllSimple: (data = {}, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", "simple", {}, success, fail),

  add: (data = { name: '', rating_max: '', rating_min: '', is_autoassigned: false, order_id: '', distribution_hour_after: '', distribution_hour_before: '', distribution_next_after_hours: ''}, success, fail) => {
    const fixedData = {...data, is_autoassigned: data.is_autoassigned ? 1 : 0 };
    commonRequest(CLASS.executorGroup, "POST", "", fixedData, success, fail);
  },

  update: (data = { id: '', name: '', rating_max: '', rating_min: '', is_autoassigned: false, order_id: '', distribution_hour_after: '', distribution_hour_before: '', distribution_next_after_hours: '' }, success, fail) => {
    const { id } = getRequestOptions(data);
    const fixedData = {...data, is_autoassigned: data.is_autoassigned ? 1 : 0 };

    commonRequest(CLASS.executorGroup, "PUT", id, fixedData, success, fail);
  },

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.executorGroup, "DELETE", data.id, {}, success, fail),

  distribute_users: (data = { }, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", 'distribute_users', {}, success, fail),
};

export const ExecutorGroupUsers: MethodType = {
  assignUser: (data = {group_id: '', user_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "POST", data.group_id + '/user', {user_id: data.user_id}, success, fail),

  deleteUser: (data = {group_id: '', user_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "DELETE", data.group_id + '/user/' + data.user_id, {}, success, fail),

  getAssignedUsers: (data = {group_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", data.group_id + '/users/assigned', {}, success, fail),

  getNotAssignedUsers: (data = {group_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "GET", data.group_id + '/users/not_assigned', {}, success, fail),

  // групповые операции
  assignAllUsers: (data = {group_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "PATCH", data.group_id + '/users/all', {}, success, fail),

  deleteAllUsers: (data = {group_id: ''}, success, fail) =>
    commonRequest(CLASS.executorGroup, "DELETE", data.group_id + '/users/all', {}, success, fail),
};

export const User: MethodType = {
  getUsers: (data = {}, success, fail) =>
    commonRequest(CLASS.user, "GET", "", {}, success, fail),

  getUsersSelect: (data = {hasUnset: 0}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.user, "GET", data.id, {}, success, fail),

  update: ( data = { id: "", email: "", phone: "", name: "", surname: "", middlename: "" }, success, fail ) => {
    const id = data.id;
    commonRequest(CLASS.user, "PUT", id, data, success, fail);
  },

  getCurrent: (data = {}, success, fail) =>
    commonRequest(CLASS.user, "GET", "current", {}, success, fail),

  getExperts: (data = {}, success, fail) =>
    commonRequest(CLASS.user, "GET", "experts", {}, success, fail),

  getExpertsSelect: (data = {hasUnset: 0}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'experts/select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  getExecutors: (data = {}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'executors', {}, success, fail),

  getExecutorsSelect: (data = {hasUnset: 0}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'executors/select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  loginAs: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.user, "POST", "login-as", data, success, fail),

  getAccessRights: (data = {}, success, fail) =>
    commonRequest(CLASS.user, "GET", 'access_rights', data, success, fail),


  // списки пользователей ----------------------------------------------------------------------------------------------
  listUsersByRoleSysname: (data = {role_sysname: ''}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'role/' + data.role_sysname, {}, success, fail),

  listUsersByRoleSysnameSelect: (data = {role_sysname: '', hasUnset: 0}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'role/' + data.role_sysname + '/select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  listUsersByRoleId: (data = {id: ''}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'role/id/' + (((data.id !== '') && (data.id !== null)) ? data.id : '0'), {}, success, fail),


  listUsersByListRoleId: (data = {id: ''}, success, fail) =>
    commonRequest(CLASS.user, 'GET', 'role/id/' + (((data.id !== '') && (data.id !== null)) ? data.id : '0') + '/list', {}, success, fail),


  // категории ---------------------------------------------------------------------------------------------------------
  assignCategory: (data = {user_id: '', is_active: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "POST", data.user_id + '/category', {category_id: data.category_id, is_active: data.is_active}, success, fail),

  updateAssignedCategory: (data = {user_id: '', is_active: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PUT", data.user_id + '/category/' + data.category_id, {is_active: data.is_active}, success, fail),

  deleteAssignedCategory: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "DELETE", data.user_id + '/category/' + data.category_id, {}, success, fail),

  deleteAssignedCategoryID: (data = {id: ''}, success, fail) => {
    const {id} = getRequestOptions(data);
    commonRequest(CLASS.user, "DELETE", 'category/' + id, {}, success, fail);
  },

  getAssignedCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "GET", data.user_id + '/categories/assigned', {}, success, fail),

  getNotAssignedCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "GET", data.user_id + '/categories/not_assigned', {}, success, fail),


  // групповые операции
  assignAllCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/categories/all', {}, success, fail),

  deleteAllCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "DELETE", data.user_id + '/categories/all', {}, success, fail),


  activateAllCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/categories/all/activate', {}, success, fail),

  deactivateAllCategories: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/categories/all/deactivate', {}, success, fail),


  // формы -------------------------------------------------------------------------------------------------------------
  assignForm: (data = {user_id: '', is_active: '', category_id: '', form_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "POST", data.user_id + '/category/' + data.category_id + '/form', {form_id: data.form_id, is_active: data.is_active}, success, fail),

  updateAssignedForm: (data = {user_id: '', is_active: '', category_id: '', form_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PUT", data.user_id + '/category/' + data.category_id + '/form/' + data.form_id, {is_active: data.is_active}, success, fail),

  deleteAssignedForm: (data = {user_id: '', category_id: '', form_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "DELETE", data.user_id + '/category/' + data.category_id + '/form/' + data.form_id, {}, success, fail),


  getAssignedForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "GET", data.user_id + '/category/' + data.category_id + '/forms/assigned', {}, success, fail),

  getNotAssignedForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "GET", data.user_id + '/category/' + data.category_id + '/forms/not_assigned', {}, success, fail),

  // групповые операции
  assignAllForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/category/' + data.category_id + '/forms/all', {}, success, fail),

  deleteAllForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "DELETE", data.user_id + '/category/' + data.category_id + '/forms/all', {}, success, fail),


  activateAllForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/category/' + data.category_id + '/forms/all/activate', {}, success, fail),

  deactivateAllForms: (data = {user_id: '', category_id: ''}, success, fail) =>
    commonRequest(CLASS.user, "PATCH", data.user_id + '/category/' + data.category_id + '/forms/all/deactivate', {}, success, fail),

};
const defaultItemFields = {is_active: false, name: "", description: "", value: "", sum: 0}
const bonusesSettings = {
  registration_bonuses_count: 0,
  app_in_row_hours: 0,
  app_in_row_bonuses_count: 0
}

export const BonusesAdminStatus: MethodType = {
  getList: (_ = {}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "GET", "status", {}, success, fail),

  createNewStatus: (data = defaultItemFields, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "POST", "status", data, success, fail),

  getListItem: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "GET", `status/${data.id}`, {}, success, fail),

  updateListItem: (data = {...defaultItemFields, id: ""}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "POST", `status/${data.id}`, data, success, fail),

  deleteListItem: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "DELETE", `status/${data.id}`, {}, success, fail),

  getSettings: (data = {}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "GET", "settings", {}, success, fail),

  changeSettings: (data = bonusesSettings, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "POST", "settings", data, success, fail),

  getBonusStatuses: (data = {}, success, fail) =>
    commonRequest(CLASS.bonusesProgram, "GET", "status/select", data, success, fail),
}

export const Bonuses: MethodType = {
  getStatus: (_ = {}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", "", {}, success, fail),

  sendBonuses: (data = {count: 0, email: "", message: ""}, success, fail) =>
    commonRequest(CLASS.bonuses, "POST", "transfer", data, success, fail),

  getHistory: (_ = {}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", "history", {}, success, fail),

  getUserHistory: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", `history/${data.id}`, {}, success, fail),

  editUserHistory: (data = {id: "", count: 0, description: "", admin_comment: ""}, success, fail) => {
    commonRequest(CLASS.bonuses, "POST", `history/${data.id}`, 
    {count: data.count, description: data.description, admin_comment: data.admin_comment}, success, fail)
  },

  getOutgoingTransfersHistory: (_ = {}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", "transfer/outgoing", {}, success, fail),

  cancelOutgoingTransfer: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.bonuses, "DELETE", `transfer/outgoing/${data.id}`, {}, success, fail),

  getIncomingTransfersList: (_ = {}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", "transfer/incoming", {}, success, fail),

  acceptIncomingTransfer: (data = {id : ""}, success, fail) =>
    commonRequest(CLASS.bonuses, "GET", `transfer/incoming/${data.id}`, {}, success, fail),

  rejectIncomingTransfer: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.bonuses, "DELETE", `transfer/incoming/${data.id}`, {}, success, fail)

}


export const Auth: MethodType = {
  login: (data = { email: "", password: "" }, success, fail) =>
    commonRequest(CLASS.auth, "POST", "login", data, success, fail),

  forgotPassword: (data = { email: "" }, success, fail) =>
    commonRequest(CLASS.auth, "POST", "forgot-password", data, success, fail),

  resetPassword: (data = { token: "", password: "" }, success, fail) =>
    commonRequest(CLASS.auth, "POST", "reset-password", data, success, fail),

  register: ( data = { name: "", surname: "", middlename: "", phone: "", email: "", password: "" }, success, fail ) =>
    commonRequest(CLASS.auth, "POST", "registration", data, success, fail),

  logout: (data = {}, success, fail) =>
    commonRequest(CLASS.auth, "POST", "logout", data, success, fail),

  infoAbout: (data = {}, success, fail) =>
    commonRequest(CLASS.auth, "POST", "me", data, success, fail),

  refreshToken: (data = { refreshToken: "" }, success, fail) =>
    commonRequest(CLASS.auth, "POST", "refresh", data, success, fail),

  setNewPassword: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.auth, "GET", "set-new-password/"+data.id, {}, success, fail),
};

export const Category: MethodType = {
  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.category, "GET", data.id, {}, success, fail),

  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.category, "GET", "", {}, success, fail),

  getAllSelect: (data = {}, success, fail) =>
    commonRequest(CLASS.category, "GET", "select", {}, success, fail),

  add: (data = { name: "", postfix: '', executor_company_id: '', app_in_row_bonuses_count: '' }, success, fail) =>
    commonRequest(CLASS.category, "POST", "", data, success, fail),

  update: (data = { id: "", name: "", postfix: '', executor_company_id: '', app_in_row_bonuses_count: '' }, success, fail) =>
    commonRequest(CLASS.category, "PUT", data.id, data, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.category, "DELETE", data.id, {}, success, fail),
};

export const Form: MethodType = {
  add: (data = { name: "", category_id: "" }, success, fail) =>
    commonRequest(CLASS.form, "POST", "", data, success, fail),

  swap: (data = { id1: "", id2: "", form: "" }, success, fail) =>
    commonRequest(CLASS.form, "POST", "swap", data, success, fail),

  change: (data = { id: "", index: "", form: "" }, success, fail) =>
    commonRequest(CLASS.form, "POST", "change", data, success, fail),

  copy: (data = { name: "", id: "" }, success, fail) =>
    commonRequest(CLASS.form, "POST", "copy", data, success, fail),

  getAll: (data = { category_id: "" }, success, fail) =>
    commonRequest( CLASS.form, "GET", "all/" + data.category_id, {}, success, fail ),

  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.form, "GET", "" + data.id, {}, success, fail),

  getByIdNew: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.form, "GET", "new/" + data.id, {}, success, fail),

  update: (data = { name: "", id: "" }, success, fail) => {
    const {id} = getRequestOptions(data);

    commonRequest(CLASS.form, "PUT", id, data, success, fail);
  },

  togglePublish: (data = { is_published: "", id: "" }, success, fail) => {
    const {id} = getRequestOptions(data);

    commonRequest(CLASS.form, "PUT", "publish/" + id, data, success, fail);
  },

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.form, "DELETE", data.id, {}, success, fail),

};

export const Chain: MethodType = {
  add: ( data = { type: "", form_id: "", parent_id: "", condition: "", tip: "", order_index: "" }, success, fail ) => {
    data.href_name = "";
    data.required  = "0";

    commonRequest(CLASS.chain, "POST", "", data, success, fail);
  },

  addFromFavorite: ( data = { id: "", type: "", form_id: "", parent_id: "", condition: "", tip: "", order_index: "" }, success, fail ) =>
    commonRequest(CLASS.chain, "POST", "fromfavorite", data, success, fail),

};

const deleteEmptyFieldsData: (data: DataArgs) => void = (data) => {
  for (const key in data) {
    const element = data[key];
    const isEmptyField = element == null;
    if (isEmptyField) delete data[key];
  }
};

export const Element: MethodType = {
  update: (data = {}, success, fail) => {
    const {id} = getRequestOptions(data);
    deleteEmptyFieldsData(data);

    commonRequest(CLASS.element, "POST", "update/" + id, data, success, fail);
  },

  structUpdate: (data = { name: "", id: "" }, success, fail) => {
    const {id} = getRequestOptions(data);
    // deleteEmptyFieldsData(data);

    commonRequest( CLASS.element, "POST", "struct/update/" + id, data, success, fail );
  },

  deleteFavorite: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.element, "DELETE", `favorite/${data.id}`, {}, success, fail ),

  addStructElement: (data = { name: "", id: "" }, success, fail) =>
    commonRequest( CLASS.element, "POST", `${data.id}/struct`, data, success, fail ),

  toggleFavorite: (data = { elem: "" }, success, fail) =>
    commonRequest(CLASS.element, "POST", `favorite`, data, success, fail),

  getAllFavorites: (data, success, fail) =>
    commonRequest(CLASS.element, "GET", `favorite`, {}, success, fail),

  getSystemNames: (data, success, fail) =>
    commonRequest(CLASS.element, "GET", `system_names`, {}, success, fail),

  deleteStructElement: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.element, "DELETE", `struct/${data.id}`, {}, success, fail ),

  deleteElement: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.element, "DELETE", data.id, {}, success, fail),

};

export const Application: MethodType = {
  list: (
      data = {
        status: "",
        executor_id: "",
        expert_id: "",
        user_id: "",
        start_time: "1920-11-10 00:00:00",
        end_time: "2090-11-10 00:00:00",
        pageAfter: "",
        pageLimit: "",
      },
      success,
      fail
  ) => commonRequest(CLASS.applications, "GET", "", data, success, fail),

  add: (data = { payer_id: "", form_id: "", answer: "" }, success, fail) =>
    commonRequest(CLASS.applications, "POST", "", data, success, fail),

  update: (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "PUT", data.id, data, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.applications, "DELETE", data.id, {}, success, fail),

  uploadFile: (data = { file: "", application_id: "" }, success, fail, load) => {
    const options = optionsUpload(load);

    commonRequest(CLASS.applications, "POST", "addfile", data, success, fail, options);
  },

  finish: (
    data = {
      id                  : "",
      answer              : "",
      is_needed           : "",
      delivery_method_id  : "",
      address             : "",
      is_adress_from_payer: "",
      contact_name        : "",
      contact_phone       : "",
      contact_email       : "",
      promocode           : "",
      wishes              : "",
    },
    success,
    fail
  ) => commonRequest(CLASS.applications, "POST", "finish", data, success, fail),

  updateProp: (data = {app_id: "", prop_id: "", option_id: ""}, success, fail) => {
    const app_id = popDataKey(data, 'app_id');

    commonRequest(CLASS.applications, "POST", app_id + '/prop', data, success, fail);
  },

  updateProjectPath: (data = {}, success, fail) =>
    commonRequest( CLASS.applications, "PUT", "setprojectpath/" + data.id, data, success, fail ),

  getAnswer: (data = {id: ""}, success, fail) =>
    commonRequest(CLASS.applications, "GET", data.id + '/answer', data, success, fail),

  updateAnswer: (data = {id: "", answer: ""}, success, fail) =>
    commonRequest(CLASS.applications, "POST", 'answer', data, success, fail),

  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.applications, "GET", data.id, {}, success, fail),

  sendDocs: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "GET", "senddocs/" + data.id, {}, success, fail ),

  loadResults: (data = { file: "", id: "" }, success, fail, load) => {
    const {id, options} = getRequestUploadOptions(data, load);

    commonRequest( CLASS.applications, "POST", `${id}/result`, data, success, fail, options );
  },

  getResult: (data = { id: "" }, success, fail, load) => {
    const options = optionsDownloadBlob(load);

    commonRequest( CLASS.applications, "GET", `${data.id}/result`, {}, success, fail, options );
  },

  deleteResult: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "DELETE", `${data.id}/result`, {}, success, fail ),

  loadPaymentOrder: (data = { file: "", id: "" }, success, fail, load) => {
    const {id, options} = getRequestUploadOptions(data, load);

    commonRequest( CLASS.applications, "POST", `${id}/paymentorder`, data, success, fail, options );
  },

  getPaymentOrder: (data = { id: "" }, success, fail, load) => {
    const options = optionsDownloadBlob(load);

    commonRequest( CLASS.applications,"GET", `${data.id}/paymentorder`, {}, success, fail, options );
  },

  deletePaymentOrder: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "DELETE", `${data.id}/paymentorder`, {}, success, fail ),

  loadSignedDocument: ( data = { file: "", id: "", type: "" }, success, fail, load ) => {
    const {id, type, options} = getRequestUploadOptions(data, load);

    commonRequest( CLASS.applications, "POST", `signeddocument/${id}?type=${type}`, data, success, fail, options );
  },

  getSignedDocument: (data = { id: "", type: "" }, success, fail, load) => {
    const {id, type, options} = getRequestDownloadOptions(data, load);

    commonRequest( CLASS.applications, "GET", `signeddocument/${id}?type=${type}`, {}, success, fail, options );
  },

  deleteSignedDocument: (data = { id: "", type: "" }, success, fail) => {
    const {id, type} = getRequestOptions(data);

    commonRequest( CLASS.applications, "DELETE", `signeddocument/${id}?type=${type}`, data, success, fail );
  },

  setSoftwareProduct: (data = { app_id: "", software_product_id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setsoftwareproduct/${data.app_id}`, { software_product_id: data.software_product_id }, success, fail ),

  setExpert: (data = { app_id: "", expert_id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setexpert/${data.app_id}`, { expert_id: data.expert_id }, success, fail ),

  setExecutor: (data = { app_id: "", executor_id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setexecutor/${data.app_id}`, { executor_id: data.executor_id }, success, fail ),

  getToWork: (data = { app_id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setworkdate/${data.app_id}`, {}, success, fail ),

  updateSystemName: ( data = { id: "", system_name_id: "", answer: "" }, success, fail ) =>
    commonRequest( CLASS.applications, "PUT", `systemname/${data.id}`, data, success, fail ),

  setWorkDeadlineDays: (data = { id: "", days: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setworkdeadlinedays/${data.id}`, data, success, fail ),

  setStatus: (data = { app_id: "", status: "" }, success, fail) =>
    commonRequest( CLASS.applications, "PUT", `setstatus/${data.app_id}`, { status: data.status }, success, fail ),

  getDeliveryById: (data = { id: "" }, success, fail) =>
    commonRequest( CLASS.applications, "GET", `${data.id}/delivery`, {}, success, fail ),

  updateDelivery: ( data = { cost: "", is_needed: "", name: "", region: "", address: "", term: "", track_number: "", id: "", }, success, fail) =>
    commonRequest(CLASS.applications, "POST", `${data.id}/delivery`, data, success, fail),

  accessRights: (data = { appId: '' }, success, fail) =>
    commonRequest(CLASS.applications, 'GET', data.appId + '/access_rights', {}, success, fail),

  log: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.applications, "GET", data.id + '/log', {}, success, fail),

  listUserAppPromocodesSelect: (data = { app_id: "", hasUnset: 0}, success, fail) =>
    commonRequest(CLASS.applications, "GET", data.app_id + '/promocodes/select' + (data.hasUnset ? '/1' : ''), data, success, fail),

  // действия ..........................................................................................................
  actions: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.applications, 'GET', data.id + '/actions', {}, success, fail),

  runAction: (data = { id: '', action_id: '' }, success, fail) =>
    commonRequest(CLASS.applications, 'POST', data.id + '/action/' + data.action_id, data, success, fail),

  // оплаты по заявке ..................................................................................................
  listPayments: (data = { app_id: ""}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , data.app_id + '/payments', data, success, fail),

  addPayment: (data = { app_id: 0, sum: 0, date: '', payment_comment: ''}, success, fail) =>
    commonRequest(CLASS.applications, "POST", data.app_id + '/payment' , data, success, fail),

  getPaymentById: (data = { id: 0}, success, fail) =>
    commonRequest(CLASS.applications, "GET"   , '/payment/' + data.id, data, success, fail),

  updatePayment:  (data = { id: 0, sum: 0, date: '', payment_comment: ''}, success, fail) =>
    commonRequest(CLASS.applications, "PUT"   , '/payment/' + data.id, data, success, fail),

  deletePayment:  (data = { id: 0}, success, fail) =>
    commonRequest(CLASS.applications, "DELETE", '/payment/' + data.id, data, success, fail),

  // фильтр ............................................................................................................
  filterUsers       : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/users'     , data, success, fail),

  filterExecutor    : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/executors' , data, success, fail),

  filterExpert      : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/experts'   , data, success, fail),

  filterSource      : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/sources'   , data, success, fail),

  filterDelivery    : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/deliveries', data, success, fail),

  filterForm        : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/forms'     , data, success, fail),

  filterCategory    : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/categories', data, success, fail),

  filterPayer       : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/payers'    , data, success, fail),

  filterStatus      : (data = {}, success, fail) =>
    commonRequest(CLASS.applications, "GET" , 'filter/statuses'  , data, success, fail),

  filterApplications: (data = {}, success, fail) =>
      commonRequest(CLASS.applications, "GET" , 'filter/applications'  , data, success, fail),


  // /api/applications/{app_id}/rating/{sysname}
  rating        : (data = { app_id: '', sysname: '' }, success, fail) =>
    commonRequest(CLASS.applications, 'GET'   , data.app_id + '/rating/' + data.sysname, {}, success, fail),

  distribution  : (data = { app_id: '' }, success, fail) =>
    commonRequest(CLASS.applications, 'GET'   , data.app_id + '/distribution', {}, success, fail),

};

export const Payers: MethodType = {
  add: (
    data = {
      type: "",
      name_ip: "",
      legal_adress: "",
      inn: "",
      ogrn: "",
      bank: "",
      bik_bank: "",
      surname: "",
      middlename: "",
      name: "",
      kor: "",
      ras: "",
      phone: "",
      email: "",
      adress: "",
      number: "",
      who_give: "",
      date_get: "",
      serial: "",
      name_org: "",
      kpp: "",
      position: "",
    },
    success,
    fail
  ) => commonRequest(CLASS.payers, "POST", "", data, success, fail),

  update: (
    data = {
      id: "",
      type: "",
      name_ip: "",
      legal_adress: "",
      inn: "",
      ogrn: "",
      bank: "",
      bik_bank: "",
      surname: "",
      middlename: "",
      name: "",
      kor: "",
      ras: "",
      phone: "",
      email: "",
      adress: "",
      number: "",
      who_give: "",
      date_get: "",
      serial: "",
      name_org: "",
      kpp: "",
      position: "",
    },
    success,
    fail
  ) => {
    const id = data.id;
    commonRequest(CLASS.payers, "PUT", id, data, success, fail);
  },

  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.payers, "GET", "", data, success, fail),

  getList: (data = {}, success, fail) =>
    commonRequest(CLASS.payers, "GET", "list", data, success, fail),

  getListSelect: (data = {}, success, fail) =>
    commonRequest(CLASS.payers, "GET", "select", data, success, fail),

  getById: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.payers, "GET", data.id, {}, success, fail),

};

export const Message: MethodType = {
  send: (data = { id: "", message: "", file: "" }, success, fail, load) => {
    const options = optionsUpload(load);

    commonRequest(CLASS.message, "POST", data.id, data, success, fail, options);
  },

  //message/private/{id}
  sendPrivate: ( data = { id: '', message: '', file: '' }, success, fail, load) => {
    const options = optionsUpload(load);

    commonRequest( CLASS.message, 'POST', 'private/' + data.id, data, success, fail, options);
  },

  getAllMessage: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.message, "GET", data.id, {}, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.message, "DELETE", data.id, {}, success, fail),

  update: (data = { id: "", message: "" }, success, fail) =>
    commonRequest(CLASS.message, "PUT", data.id, data, success, fail),

  loadAttachedFile: (data = { file_id: "" }, success, fail, load) => {
    const options = optionsDownloadBlob(load);

    commonRequest(CLASS.message, "GET", "file/" + data.file_id, {}, success, fail, options);
  },
};

export const Company: MethodType = {
  list: (data = {}, success, fail) =>
    commonRequest(CLASS.company, "GET", "", {}, success, fail),

  listSelect: (data = {}, success, fail) =>
    commonRequest(CLASS.company, "GET", "select", {}, success, fail),

  get: (data = {id: ''}, success, fail) =>
    commonRequest(CLASS.company, "GET", data.id, {}, success, fail),

  add: (data = {}, success, fail, load) => {
    const {options} = getRequestUploadOptions(data, load);

    commonRequest(CLASS.company, "POST", "", data, success, fail, options);
  },

  update: (data = {id: ''}, success, fail, load) => {
    const {id, options} = getRequestUploadOptions(data, load);

    commonRequest(CLASS.company, "POST", id, data, success, fail, options);
  },

  delete: (data = {id: ''}, success, fail) =>
    commonRequest(CLASS.company, "DELETE", data.id, {}, success, fail),

  // перенести в POST:add / PUT:update
  signature: (data = { file: "" }, success, fail) =>
    commonRequest(CLASS.company, "POST", "signature", data, success, fail),

  print: (data = { file: "" }, success, fail) =>
    commonRequest(CLASS.company, "POST", "print", data, success, fail),

};

export const Settings: MethodType = {
  get: (data = {}, success, fail) =>
    commonRequest(CLASS.settings, "GET", "", {}, success, fail),

  update: (data = { email_chat_events: '', deadline_days: '' }, success, fail) =>
    commonRequest(CLASS.settings, "PUT", "", data, success, fail),

};

export const Delivery: MethodType = {
  add: (data = { name: "", price: "" }, success, fail) =>
    commonRequest(CLASS.delivery, "POST", "", data, success, fail),

  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.delivery, "GET", "", {}, success, fail),

  getAllSimple: (data = {}, success, fail) =>
    commonRequest(CLASS.delivery, "GET", "select", {}, success, fail),

  update: (data = { name: "", price: "", id: "" }, success, fail) =>
    commonRequest(CLASS.delivery, "PUT", data.id, data, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.delivery, "DELETE", data.id, {}, success, fail),

};

export const Source: MethodType = {
  add: (data = { name: "" }, success, fail) =>
    commonRequest(CLASS.source, "POST", "", data, success, fail),

  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.source, "GET", "", {}, success, fail),

  getAllSimple: (data = {}, success, fail) =>
    commonRequest(CLASS.source, "GET", "simple", {}, success, fail),

  update: (data = { name: "", id: "" }, success, fail) =>
    commonRequest(CLASS.source, "PUT", data.id, data, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.source, "DELETE", data.id, {}, success, fail),
};

export const Promocode: MethodType = {
  add: (data = { name: "", value: "" }, success, fail) =>
    commonRequest(CLASS.promocode, "POST", "", data, success, fail),

  update: (data = { id: "", name: "", value: "" }, success, fail) =>
    commonRequest(CLASS.promocode, "PUT", data.id, data, success, fail),

  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.promocode, "GET", "", {}, success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.promocode, "DELETE", data.id, {}, success, fail),

  isExist: (data = { name: "" }, success, fail) =>
    commonRequest(CLASS.promocode, "POST", "find", data, success, fail),
};



export const SystemActions: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'action', {}, success, fail),

  select: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'action/select', {}, success, fail),

  add: (data = {is_active: '', sysname: '', title: '', description: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'action', prepareDataIsActive(data), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'action/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', sysname: '', title: '', description: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'action/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'action/' + data.id, {}, success, fail),
};

export const SystemRoles: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role', {}, success, fail),

  select: (data = {hasUnset: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/select', data, success, fail),

  add: (data = {is_active: '', sysname: '', title: '', description: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role', prepareDataIsActive(data), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', sysname: '', title: '', description: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'role/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'role/' + data.id, {}, success, fail),


  getAssignedStatuses: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.id + '/status/assigned', {}, success, fail),


  getAssignedAccessRights: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.id + '/access_rights', {}, success, fail),

  assignAccessRight: (data = { role_id: '', id: '', is_active: '', props: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.role_id + '/access_right/' + data.id, {}, success, fail),

};

export const SystsemRoleAccessRights: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/access_right', {}, success, fail),

  select: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/access_right/select', {}, success, fail),

  add: (data = {is_active: '', role_id: '', access_right_id: '', value: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role/access_right', prepareDataIsActive(data), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/access_right/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', role_id: '', access_right_id: '', value: '', use_default_value: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'role/access_right/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'role/access_right/' + data.id, {}, success, fail),

  getByRoleId: (data = { role_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.role_id + '/access_right/assigned', {}, success, fail),

};

export const SystemAccessRights: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'access_right', {}, success, fail),

  select: (data = {type: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'access_right/select', data, success, fail),

  add: (data = {is_active: '', name: '', description: '', default_value: '', values_list: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'access_right', prepareDataIsActive(data), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'access_right/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', name: '', description: '', default_value: '', values_list: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'access_right/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'access_right/' + data.id, {}, success, fail),

  // SystemAccessRightValues:
  getValues: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/' + data.id + '/values', {}, success, fail),

  getValuesSelect: (data = { id: '', hasUnset: '' }, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/' + data.id + '/values/select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  addValue: (data = {id: '', is_active: '', sysname: '', name: '', description: '', default_props: '', order_id: ''}, success, fail) =>
    commonRequest(CLASS.system, 'POST', 'access_right/' + data.id + '/value', prepareDataIsActive(data), success, fail),

  getValueById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'access_right/value/' + data.id, {}, success, fail),

  updateValue: (data = { id: '', is_active: '', sysname: '', name: '', description: '', default_props: '', order_id: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'access_right/value/' + data.id, prepareDataIsActive(data), success, fail),

  deleteValue: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'access_right/value/' + data.id, {}, success, fail),

  // templates
  getValueTemplatesSelect: (data = {}, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/value/templates/select', {}, success, fail),

  useValueTemplate: (data = {id: '', template_id: ''}, success, fail) =>
    commonRequest(CLASS.system, 'POST', 'access_right/' + data.id + '/value/template/' + data.template_id, {}, success, fail),

  // SystemAccessRightTargets:
  listTargetsSelect: (data = { }, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/targets/select', {}, success, fail),

  listTargetsAssigned: (data = { access_right_id: '' }, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/' + data.access_right_id + '/targets', {}, success, fail),

  addTargetAssigned: (data = {access_right_id: '', is_active: '', target_type: ''}, success, fail) =>
    commonRequest(CLASS.system, 'POST', 'access_right/' + data.access_right_id + '/target', prepareDataIsActive(data), success, fail),

  getTargetAssignedById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'access_right/target/assigned/' + data.id, {}, success, fail),

  updateTargetAssigned: (data = { id: '', is_active: '', target_type: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'access_right/target/assigned/' + data.id, prepareDataIsActive(data), success, fail),

  deleteTargetAssigned: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'access_right/target/assigned/' + data.id, {}, success, fail),

};

export const SystemAccessRightsAssigned: MethodType = {
  getByRoleId: (data = {role_id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.role_id + '/access_rights', {}, success, fail),

  getByRoleStatusId: (data = {role_status_id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/' + data.role_status_id + '/access_rights', {}, success, fail),

  getByUserId: (data = {user_id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'user/' + data.user_id + '/access_rights', {}, success, fail),

  getByStatusId: (data = {status_id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/' + data.status_id + '/access_rights', {}, success, fail),


  addToRole: (data = {is_active: '', role_id: '', access_right_id: '', props: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role/' + data.role_id + '/access_right/' + data.access_right_id, prepareDataIsActive(data), success, fail),

  addToRoleStatus: (data = {is_active: '', role_status_id: '', access_right_id: '', props: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role/status/' + data.role_status_id + '/access_right/' + data.access_right_id, prepareDataIsActive(data), success, fail),

  addToUser: (data = {is_active: '', user_id: '', access_right_id: '', props: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'user/' + data.user_id + '/access_right/' + data.access_right_id, prepareDataIsActive(data), success, fail),

  addToStatus: (data = {is_active: '', status_id: '', access_right_id: '', props: '', use_default_value: ''}, success, fail) =>
    commonRequest(CLASS.system, "POST", 'status/' + data.status_id + '/access_right/' + data.access_right_id, prepareDataIsActive(data), success, fail),


  // assigned values
  getById: (data = {id: ''}, success, fail) =>
    commonRequest(CLASS.system, 'GET', 'access_right/value/assigned/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', access_right_id: '', props: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'access_right/value/assigned/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: ''}, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'access_right/value/assigned/' + data.id, {}, success, fail),
}

export const SystemStatuses: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status', {}, success, fail),

  select: (data = {hasUnset: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/select', data, success, fail),

  add: (data = { is_active: '', title: '', description: '', color: '', is_colored: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'status', prepareDataIsActive(data, ['is_colored']), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', title: '', description: '', color: '', is_colored: ''}, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'status/' + data.id, prepareDataIsActive(data, ['is_colored']), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'status/' + data.id, {}, success, fail),

  getStructure: (data = {role_id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/structure', data, success, fail),
};

export const SystemRoleStatuses: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status', {}, success, fail),

  select: (data = {hasUnset: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/select' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  selectIinstanceOf: (data = {hasUnset: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/selectInstanceOf' + (data.hasUnset ? '/1' : ''), {}, success, fail),

  add: (data = { is_active: '', role_id: '', status_id: '', title: '', description: '', has_parent_title: '', has_parent_description: '', has_parent_color: '', is_colored: '', color: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role/status', prepareDataIsActive(data, ['has_parent_title', 'has_parent_description', 'has_parent_color', 'is_colored']), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', role_id: '', status_id: '', title: '', description: '', has_parent_title: '', has_parent_description: '', has_parent_color: '', is_colored: '', color: ''  }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'role/status/' + data.id, prepareDataIsActive(data, ['has_parent_title', 'has_parent_description', 'has_parent_color', 'is_colored']), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'role/status/' + data.id, {}, success, fail),


  getByStatusId: (data = { status_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/' + data.status_id + '/roles/assigned', {}, success, fail),

  getByRoleId: (data = { role_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/' + data.role_id + '/statuses/assigned', {}, success, fail),


  copyInstanceActions: (data = { id: ''}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/' + data.id + '/copy_instance_actions', {}, success, fail),
};

export const SystemRoleStatusActions: MethodType = {
  getAll : (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/action', {}, success, fail),

  select : (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/action/select', {}, success, fail),

  add    : (data = { is_active: '', role_status_id: '', action_id: '', set_status_id: '', title: '', description: '', props: '', has_parent_title: '', has_parent_description: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'role/status/action', prepareDataIsActive(data, ['has_parent_title', 'has_parent_description']), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/action/' + data.id, {}, success, fail),

  update : (data = { id: '',  is_active: '', role_status_id: '', action_id: '', set_status_id: '', title: '', description: '', props: '', has_parent_title: '', has_parent_description: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'role/status/action/' + data.id, prepareDataIsActive(data, ['has_parent_title', 'has_parent_description']), success, fail),

  delete : (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'role/status/action/' + data.id, {}, success, fail),


  getByRoleStatusId: (data = { role_status_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'role/status/' + data.role_status_id + '/actions/assigned', {}, success, fail),

  getByActionId    : (data = { action_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'action/' + data.action_id + '/role_actions/assigned', {}, success, fail),
};

export const SystemEmails: MethodType = {
  getAll: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'email', {}, success, fail),

  getAllActive: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'email/active', {}, success, fail),

  select: (data = {}, success, fail) =>
    commonRequest(CLASS.system, "GET", 'email/select', {}, success, fail),

  add: (data = { is_active: '', sysname: '', to: '', title: '', text: '', add_app_link_text: '', add_app_chat_link_text: '', add_no_reply_text: '', files: '', description: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'email', prepareDataIsActive(data, ['add_app_link_text', 'add_app_chat_link_text', 'add_no_reply_text']), success, fail),

  getById: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'email/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', sysname: '', to: '', title: '', text: '', add_app_link_text: '', add_app_chat_link_text: '', add_no_reply_text: '', files: '', description: ''}, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'email/' + data.id, prepareDataIsActive(data, ['add_app_link_text', 'add_app_chat_link_text', 'add_no_reply_text']), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'email/' + data.id, {}, success, fail),


  // assigned
  listAssignedToStatus: (data = { status_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'status/' + data.status_id + '/mails', {}, success, fail),

  assignToStatus: (data = { status_id: "", mail_id: "", is_active: '', type_id: '', is_replace_to: '', to: '', is_split_send: '', comment: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'status/' + data.status_id + '/mail/' + data.mail_id, prepareDataIsActive(data, ['is_replace_to', 'is_split_send']), success, fail),


  listAssignedToAction: (data = { action_id: "" }, success, fail) =>
    commonRequest(CLASS.system, "GET", 'action/' + data.action_id + '/mails', {}, success, fail),

  assignToAction: (data = { action_id: "", mail_id: "", is_active: '', type_id: '', is_replace_to: '', to: '', is_split_send: '', comment: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'action/' + data.action_id + '/mail/' + data.mail_id, prepareDataIsActive(data, ['is_replace_to', 'is_split_send']), success, fail),


  updateAssigned: (data = { status_id: "", mail_id: "", is_active: '', type_id: '', is_replace_to: '', to: '', is_split_send: '', comment: '' }, success, fail) =>
    commonRequest(CLASS.system, "PUT", 'email/assigned/' + data.id, prepareDataIsActive(data, ['is_replace_to', 'is_split_send']), success, fail),

  deleteAssigned: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.system, "DELETE", 'email/assigned/' + data.id, {}, success, fail),


  testSend: (data = { id: "", to: '', application_id: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'email/' + data.id + '/send', data, success, fail),

  testSendAssigned: (data = { id: "", to: '', application_id: '' }, success, fail) =>
    commonRequest(CLASS.system, "POST", 'email/assigned/' + data.id + '/send', data, success, fail),

};

export const Rating: MethodType = {
  list: (data = {}, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , '', {}, success, fail),

  create: (data = { is_active: '', title: '', description: '', sysname: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , '', prepareDataIsActive(data, []), success, fail),

  read: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , data.id, {}, success, fail),

  update: (data = { id: '',  is_active: '', title: '', description: '', sysname: ''}, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: "" }, success, fail) =>
    commonRequest(CLASS.rating, 'DELETE', data.id, {}, success, fail),

  listSelect: (data = {}, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , 'select', {}, success, fail),

};

export const RatingRole: MethodType = {
  list: (data = { rating_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , data.rating_id + '/roles', {}, success, fail),

  create: (data = { is_active: '', rating_id: '', role_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'role', prepareDataIsActive(data), success, fail),

  read: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , 'role/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', role_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'role/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'DELETE', 'role/' + data.id, {}, success, fail),

};

export const RatingScore: MethodType = {
  list: (data = { rating_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , data.rating_id + '/scores', {}, success, fail),

  create: (data = { is_active: '', title: '', description: '', calc_action_name: '', order_id: '', rating_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'score', prepareDataIsActive(data), success, fail),

  read: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , 'score/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', title: '', description: '', calc_action_name: '', order_id: ''}, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'score/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'DELETE', 'score/' + data.id, {}, success, fail),
};

export const RatingScoreValue: MethodType = {
  list: (data = { rating_score_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , 'score/' + data.rating_score_id + '/values', {}, success, fail),

  create: (data = { is_active: '', value: '', description: '', sysname: '', order_id: '', rating_score_id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'score/value', prepareDataIsActive(data), success, fail),

  read: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'GET'   , 'score/value/' + data.id, {}, success, fail),

  update: (data = { id: '', is_active: '', value: '', description: '', sysname: '', order_id: ''}, success, fail) =>
    commonRequest(CLASS.rating, 'POST'  , 'score/value/' + data.id, prepareDataIsActive(data), success, fail),

  delete: (data = { id: '' }, success, fail) =>
    commonRequest(CLASS.rating, 'DELETE', 'score/value/' + data.id, {}, success, fail),
};
