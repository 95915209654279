// prettier-ignore-start
import React from "react";

import { useParams } from "react-router-dom";

import {
    SystemActions,
    SystemRoleStatusActions,
    SystemRoleStatuses, SystemStatuses,
}                         from "../../../api/methods";
import { useDataRequest } from "../../../hooks/useDataRequest";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import ListFormat_2Lines  from "../../../components/Lists/Parts/ListFormat_2Lines";
import {isEmpty} from "../../../functions/common";
import listFormat_2Lines from "../../../components/Lists/Parts/ListFormat_2Lines";


const SettingsSystemStatusRoleActionsList = ({
    getTitle = (roleStatus) => {},
    getBack = (roleStatus) => {},
}) => {
    const { role_status_id } = useParams();

    const roleStatus = useDataRequest({
        autoLoad    : true,
        request     : SystemRoleStatuses.getById,
        requestData : { id: role_status_id },
    });

    const formatSetStatus   = (id, item, itemProps) => {
        const set_status      = item?.set_status?.title      ?? '';
        const set_fail_status = item?.set_fail_status?.title ?? '';

        if (isEmpty(set_fail_status)) return set_status;

        return listFormat_2Lines(set_status, set_fail_status);
    }
    const formatActionTitle = (id, item, itemProps) => ListFormat_2Lines(item?.title, item?.action?.sysname);

    const optionsAction     = (success) => SystemActions.select ({}           , ({data}) => success(data));
    const optionsSetStatus  = (success) => SystemStatuses.select({hasUnset: 1}, ({data}) => success(data));


    const titleParentTitle       = 'наследовать название действия';
    const titleParentDescription = 'наследовать описание ';

    return <List
        title        = {getTitle(roleStatus.data)}
        popupPrefix  = {'systemStatusRoleActions'}
        getListData  = {(data, s, f) => SystemRoleStatusActions.getByRoleStatusId({role_status_id}, s, f)}

        cols         = {[
          {name: 'id'                    , title: 'id'                              , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false, canEdit: false},

          {name: 'is_active'             , title: 'активность'                      , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: false, align: 'center'},
          {name: 'action_id'             , title: 'действие'                        , format: LIF.hidden  , type: LIT.select, canAddAndEdit: true , default: null , align: 'left', getOptions: optionsAction },
          {name: 'has_parent_title'      , title: titleParentTitle                  , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: false},
          {name: 'title'                 , title: 'название действия'               , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''   , align: 'left', customFormatHandler: formatActionTitle },
          {name: 'has_parent_description', title: titleParentDescription            , format: LIF.hidden  , type: LIT.check , canAddAndEdit: true , default: false},
          {name: 'description'           , title: 'описание'                        , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''   , align: 'left'},
          {name: 'props'                 , title: 'параметры'                       , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''   , align: 'left'},
          {name: 'set_status_id'         , title: 'в статус'                        , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null , align: 'left', getOptions: optionsSetStatus, customFormatHandler: formatSetStatus },
          {name: 'set_fail_status_id'    , title: 'в статус при неудачных проверках', format: LIF.hidden  , type: LIT.select, canAddAndEdit: true , default: null , getOptions: optionsSetStatus},
          {name: 'order_id'              , title: 'позиция сортировки'              , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true , default: 0    },
        ]}
        buttons      =  {[
            {type: 'group', buttons: [
                {type: 'back', to: getBack(roleStatus.data) },
            ]},
            {type: 'new'},
        ]}

        onNew        = {(data, s, f) => SystemRoleStatusActions.add({...data, role_status_id, }, s, f)   }
        onEdit       = {SystemRoleStatusActions.update}
        onDelete     = {SystemRoleStatusActions.delete}

        textOnDelete = {(item) => <>Удалить действие <b>{item?.title ?? ''}</b>?</>}
    />

};

export default SettingsSystemStatusRoleActionsList;
