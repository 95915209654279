// prettier-ignore-start
import React, { ReactNode, useEffect } from 'react';

import { Helmet }                   from "react-helmet";
import { Container, Row }           from "styled-bootstrap-grid";
import { Link, useHistory }         from "react-router-dom";

import { T }                        from '../../../lang/ru';
import { PATHS }                    from "../../../constants/RoutesPaths";
import {  gray }                    from "../../../constants/Colours";
import { isProPBSystem }            from "../../../constants/SystemType";

import { Bonuses, User }            from "../../../api/methods";
import { setBonusesStatus }         from "../../../store/bonuses/actions";
import { bonusesStatus }            from "../../../models/bonuses.model";
import { storageData }              from "../../../functions/auth";
import { reloadPage }               from "../../../functions/reloadPage";
import { useAppDispatch,
  useAppSelector }                  from "../../../hooks/useRedux";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
import { useDataRequest }           from "../../../hooks/useDataRequest";
import { useSupportedBrowser }      from "../../../hooks/useSupportedBrowser";

import SecondOrderButton            from "../../UI-kit/Buttons/SecondOrderButton";
import BonusesValue                 from "../../UI-kit/BonusesValue";
import Subtitle                     from '../../UI-kit/Subtitle';
import { PhoneBlock }               from "../Header/PhoneBlock";
import Header                       from '../Header/Header';
import Footer                       from "../Footer/Footer";
import Links                        from "../Links/Links";
import { YoutubeLink }              from "../YoutubeLink/YoutubeLink";
import RatingValues                 from "../../UI-kit/RatingValues";


type Props = {
    children: ReactNode;
};

const LayoutForRoutes: React.FC<Props> = ({ children }) => {

    const userAccess: any = useUserAccessRightChecks();

    const history = useHistory();

//    const isHiddenButton = useSelector(getIsShowButton);
    const dispatch     = useAppDispatch();
    const bonuses      = useAppSelector(state => state?.bonuses?.status)
    const user_fio     = localStorage.getItem(storageData.user_fio) ?? '';
    const role         = localStorage.getItem(storageData.role    ) ?? '';


    useEffect(() => {
        if (userAccess.isAllowUserBonusProgram) {
            Bonuses.getStatus(
                {},
                (data: {data: bonusesStatus}) => dispatch(setBonusesStatus(data.data))
            )
        }
    }, [ userAccess.isAllowUserBonusProgram ]);

    const pushToPath: (path: string) => void = (path) => {
        history.push(path);
    };

    const { pathname } = document.location;

    const isHiddenLogoutButton =
        pathname.includes(PATHS.login          ) ||
        pathname.includes(PATHS.register       ) ||
        pathname.includes(PATHS.agreement      ) ||
        pathname.includes(PATHS.registerSuccess);

    const links = isHiddenLogoutButton ? [] : [
        ...( userAccess.isAllowSysSettings         ? [{ name: T.editForms, onClick: () => pushToPath(PATHS.formEditSetCategory) }] : [] ),
        ...( userAccess.isAllowSysSettingsFormEdit ? [{ name: 'Настройки', onClick: () => pushToPath(PATHS.settings.default   ) }] : [] ),
        { name: 'Выход', onClick: () => logout() },
    ];

    const logout = () => {
        localStorage.clear();
        document.location.replace(PATHS.login);
    };

/*
    const goToChoosingCustomer = () => {
        dispatch(setIsHiddenMobileButton(false));
        history.push(PATHS.customer.requestCustomers);
    };
*/

    const accessRights = useDataRequest({
        request: (d, s, f) => User.getAccessRights({}, (data) => {
            s(data);
            localStorage.setItem(storageData.accessRights, JSON.stringify(data.data));
            reloadPage();
        }, f )
    });

    const isSupportedBrowser = useSupportedBrowser();


    if (!isSupportedBrowser) {
        return (
            <Container style={{ width: '-webkit-fill-available', minHeight: 800 }}>
                {children}
            </Container>
        );
    }

    return <>
        <Helmet>
            <title>{isProPBSystem ? 'Фабрика проектов' : 'Фабрика расчетов'}</title>
            <meta
                name="description"
                content={
                    isProPBSystem
                        ? 'Аутсорсинг проектов в сфере пожарной безопасности на Фабрике Проектов'
                        : 'Аутсорсинг расчетов в сфере пожарной безопасности на Фабрике Расчетов'
                }
            />
        </Helmet>

        <Header>
            <Container style={{ width: '-webkit-fill-available' }}>
                <Row
                    style          = {{ margin: 0 }}
                    justifyContent = {
                        isHiddenLogoutButton ? 'between' : undefined
                    }
                    alignItems     = "center"
                >
                    {userAccess.isAllowSysHeadTitle && <PhoneBlock className="header-block">
                        <Link to={isHiddenLogoutButton ? PATHS.login : PATHS.default}>
                            <Subtitle>
                                {userAccess.propSysHeadTitle}
                                <br className="header-logo" />
                            </Subtitle>
                        </Link>
                    </PhoneBlock>}

                    {userAccess.isAllowSysHeadPhone && <PhoneBlock className="header-block">
                        <Subtitle style={{ color: gray }}>
                            <a href={'tel:'+userAccess.propSysHeadPhone.replace(/\s/g,'')}>
                                {userAccess.propSysHeadPhone}
                            </a>
                        </Subtitle>
                    </PhoneBlock>}

                    {userAccess.isAllowSysHeadYoutubeLink && <YoutubeLink />}

                    {(userAccess.isAllowSysShowUserName || userAccess.isAllowUserBonusProgram) &&
                        <PhoneBlock className="header-block">
                            <span style={{fontWeight: 'bold', fontSize: '16px', marginLeft: 15}}>
                                {(userAccess.isAllowSysShowUserName) &&
                                    <p style={{margin: 0}}>
                                        {user_fio}
                                        {((userAccess.isAllowSysShowUserRole) && (role!=='')) && <> ({role})</>}
                                    </p>
                                }
                                {(userAccess.isAllowUserBonusProgram && bonuses?.is_bonus_program_member) && <BonusesValue />}
                                <RatingValues />
                            </span>
                        </PhoneBlock>
                    }

                    <Row
                        style={{
                            margin: 0,
                            marginLeft: 'auto',
                        }}
                    >
                        {(! isHiddenLogoutButton) && <Links links={links} />}

                        {userAccess.isAllowSysUpdateAccessButton && <SecondOrderButton
                            disabled = {accessRights.isLoading}
                            onClick  = {() => accessRights.reload()}
                            style    = {{fontSize: '26px', fontWeight: 'bold', padding: '10px 5px 10px 5px'}}
                        >
                            {accessRights.isLoading ? <>&hellip;</> : (accessRights.isLoaded ? <>&#10004;</> : <>&#8635;</>)}
                        </SecondOrderButton>}
                    </Row>
                </Row>
            </Container>
        </Header>
        <Container style={{ width: '-webkit-fill-available', minHeight: 800 }}>
            {children}
        </Container>
        {/* <Loader /> */}
        {/*
              {isHiddenButton ? (
                <MobileButton onClick={goToChoosingCustomer}>
                  {T.orderCalculation}
                </MobileButton>
              ) : (
                <></>
              )}
        */}
        <Footer></Footer>
    </>;

};

export default LayoutForRoutes;
