// prettier-ignore-start
import React, { useState } from "react";

import { useParams }          from "react-router-dom";
import { useDispatch }        from "react-redux";

import { SystemAccessRights } from "../../../api/methods";
import { openPopup }          from "../../../store/popups/actions";
import { reloadPage }         from "../../../functions/reloadPage";
import { useDataRequest }     from "../../../hooks/useDataRequest";

import { PATHS }              from "../../../constants/RoutesPaths";
import List                   from "../../../components/Lists/List";
import LIF                    from "../../../components/Lists/ListItemFormats";
import LIT                    from "../../../components/Lists/ListItemTypes";
import PopupEdit              from "../../../components/Lists/PopupEdit";


const SettingsSystemAccessRightValues = () => {

    const { access_right_id } = useParams();
    const dispatch = useDispatch();

    const accessRight = useDataRequest({
        autoLoad    : true,
        request     : SystemAccessRights.getById,
        requestData : { id: access_right_id },
    });

    const [template   , setTemplate   ] = useState(null);

    const optionsSetAccessRightValueTemplates = (success) => SystemAccessRights.getValueTemplatesSelect({}, ({data}) => success(data));

    const popupNameValueTemplate = 'systemAccessRightValueTemplate';


    return ( <>
        <List
            title        = {<>{accessRight.data.name ?? 'Доступ'} ({accessRight.data.sysname ?? 'name'})</>}
            popupPrefix  = {'systemAccessRightValuesList'}
            getListData  = {(data, success, fail) => SystemAccessRights.getValues({id: access_right_id}, success, fail)}

            cols         = {[
              {name: 'id'                 , title: 'id'                    , format: LIF.linkEdit, type: LIT.number  , canAddAndEdit: false, canEdit: false},
              {name: 'is_active'          , title: 'активность'            , format: LIF.check   , type: LIT.check   , canAddAndEdit: true , default: 0},
              {name: 'sysname'            , title: 'системное имя'         , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
              {name: 'name'               , title: 'имя'                   , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
              {name: 'description'        , title: 'описание'              , format: LIF.linkEdit, type: LIT.textarea, canAddAndEdit: true , default: '', align: 'left'},
              {name: 'default_props'      , title: 'параметры по-умолчанию', format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
              {name: 'order_id'           , title: 'сортировка'            , format: LIF.linkEdit, type: LIT.number  , canAddAndEdit: true , default: 0},
              {name: 'default_value_count', title: 'по-умолчанию'          , format: LIF.check       },
              {name: 'assigned_count'     , title: 'использ'          , format: LIF.usageCounter},
            ]}
            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: PATHS.settings.systemAccessRights},
                ]},
                {type: 'new'},
                {title: 'из шаблона', onClick: () => {
                    setTemplate({id: access_right_id});
                    dispatch( openPopup ( popupNameValueTemplate ));
                }}
            ]}

            onNew        = {(data, s, f) => SystemAccessRights.addValue({...data, id: access_right_id}, s, f)   }
            onEdit       = {SystemAccessRights.updateValue}
            onDelete     = {SystemAccessRights.deleteValue}

            canDelete    = {(item) => (item['assigned_count'] === 0) && (item['default_value_count'] === 0)}

            textOnDelete = {(item) => <>Удалить значение <b>{item?.name ?? ''}</b>?</>}
        />

        <PopupEdit
            isNewPopup  = {true}
            popupName   = {popupNameValueTemplate}
            key         = {popupNameValueTemplate}
            title       = ''
            doText      = 'Использовать'
            getData     = {reloadPage}
            item        = {template}
            setItem     = {setTemplate}
            cols        = {[
                { name: 'template_id', title: 'шаблон', type: LIT.select, getOptions: optionsSetAccessRightValueTemplates },
            ]}
            action      = {SystemAccessRights.useValueTemplate}
        />
    </> );

};

export default SettingsSystemAccessRightValues;
