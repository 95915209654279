
type ObjectWithKey = {
  key: string;
};

type Result = {
  [key: string]: string;
};

export const formDataFromInputInfoForState: (
  fields: ObjectWithKey[]
) => Result = (fields) => {
  let data: Result = {};
  fields.map(({ key }) => (data[key] = ""));

  return data;
};

export const goToHref = (href: string) => window.open(href);

export const checkFullnessFields = (array: []) => {
  for (const item of array) {
    if (!item) return true;
  }

  return false;
};

export const isEmptyListArray = (array: any[]) => {
  if (!array?.length) return true;
  if (array.length === 1 && array[0] === "") return true;
  return false;
};


export const getParentId = (id: string, parentId: string) => {
  const idParts = id.split("_")
  if (idParts.length === 1) {
    return parentId 
  } else {
    return [parentId, ...idParts.slice(1).slice(0, -1)].join("_")
  }
}

export const dateToday = () => {
  return (new Date()).toISOString().split("T")[0];
}

export const dateTranslate = (date: any) => {
  if (!date) return "";

  date = date.split(" ")[0]; //  : "10.10.2000"
  date = new Date(Date.parse(date));
  date = date.toLocaleDateString("ru-RU", {
    year: "numeric", //
    month: "2-digit", // long
    day: "2-digit", // numeric
  });

  return date;
};

export const timeTranslate = (date: any) => {
  if (!date) return "";

  let time = (date !== undefined) ? date.split(" ")[1] : "00:00:00";
  time = time.split(":");

  return time[0]+':'+time[1];
};

export const dateTranslateToApi = (date: any) => {
  date = String(date).split(".").reverse().join("-");

  return date;
};

export const dateTranslateFromApi = (date: any) => {
  date = String(date).split("-").reverse().join(".");

  return date;
};

export const numberTranslate = (number: any) =>
    new Intl.NumberFormat('ru-RU').format(number);

export const NumbToLocale = (value:any) =>
    value.toLocaleString('ru-RU', { maximumFractionDigits: 2 });

export function debounce(f: any, ms: number) {
  let isCooldown = false;

  return function (this: any) {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);
  };
}

export const isEmpty    = (value?: any) => value === "" || value === null || value === 'null' || value === undefined || value === 'undefined';
export const isNotEmpty = (value?: any) =>! isEmpty(value);

export const ifEmpty = (value?: any, defaultValue?: any) =>
  isEmpty(value) ? defaultValue : value;
