// prettier-ignore-start
import React from "react";

import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import {downloadData            } from "../../../components/File/DownloadData";
import FileHandler                from "../../../components/File/FileHandler";
import {Empty                   } from "../../../components/Empty";


const OrderInfoDocsGeneration = ({applicationData}) => {

    const userAccess = useUserAccessRightChecks();

    const {
        contract_number,
        docs_generation = [],
        executor_company_id,
    } = applicationData;

    const getFileName = (docName, docType) => {
        let [newDocName] = docName.split("(");

        newDocName = newDocName.replace(" ", "");
        newDocName += `. Заказ №${contract_number}`;
        newDocName += "." + docType;

        return newDocName;
    }


    if (! userAccess.isAllowAppDataDocsGeneration) return null;
    if (docs_generation.length === 0             ) return <Empty key={0} text='отсутствуют' />
    if (! executor_company_id                    ) return '* выберите организацию-исполнителя по договору';
    return (
        Object.keys(docs_generation).map((docName, index) => {
            const { href, docType } = docs_generation[docName];

            const handleDownload = (data, success, fail, load) => downloadData(href, "blob", success, fail, load);


            return (
                <>
                    <FileHandler
                        key                = {index}
                        fileName           = {getFileName(docName, docType)}
                        downloadOnly       = {true}
                        onDownload         = {handleDownload}
                        downloadButtonText = {docName}
                        subjectIm          = {`: ${docName}`}
                    />
                    {index % 2 === 1 ? <br key={100+index} /> : null}
                </>
            );
        })
    );

};

export default OrderInfoDocsGeneration;