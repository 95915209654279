// prettier-ignore-start
import React from "react";

import { useParams }                    from "react-router-dom";
import { SystemEmails, SystemStatuses } from "../../../api/methods";
import { useDataRequest }               from "../../../hooks/useDataRequest";
import { PATHS }                        from "../../../constants/RoutesPaths";
import SettingsSystemMailsAssigned      from "./SettingsSystemMailsAssigned";


const SettingsSystemStatusMails = () => {

    const { status_id } = useParams();

    const status = useDataRequest({
        autoLoad    : true,
        request     : SystemStatuses.getById,
        requestData : { id: status_id },
    });


    return <SettingsSystemMailsAssigned
        types       = {[ {id: 2, name: 'При установке данного статуса'}, {id: 3, name: 'При смене статуса на другой'} ]}
        title       = {<>Статус "{status.data.title ?? '-'}" / Уведомления:</>}
        backTo      = {PATHS.settings.systemStatuses}
        getListData = {(data, success, fail) => SystemEmails.listAssignedToStatus({         status_id}, success, fail)}
        onNew       = {(data, success, fail) => SystemEmails.assignToStatus      ({...data, status_id}, success, fail)}
    />

};

export default SettingsSystemStatusMails;
