// prettier-ignore-start
import React from "react";

import {Delivery} from "../../../api/methods";
import { T }      from "../../../lang/ru";

import List       from "../../../components/Lists/List";
import LIF        from "../../../components/Lists/ListItemFormats";
import LIT        from "../../../components/Lists/ListItemTypes";


const SettingsListsDeliveries = () => {
    return (
        <List
            popupPrefix  = {'delivery'}
            getListData  = {Delivery.getAll}
            cols         = {[
                {name: 'name'              , title: 'название', format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true, isValid: (val) => val !== ""},
                {name: 'price'             , title: 'цена'    , format: LIF.linkEdit    , type: LIT.number, canAddAndEdit: true, isValid: (val) => val !== ""},
                {name: 'applications_count', title: 'заявок'  , format: LIF.usageCounter},
            ]}
            buttons      =  {[
                {type: 'new'},
            ]}

            onNew        = {Delivery.add}
            onEdit       = {Delivery.update}
            onDelete     = {Delivery.delete}

            canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteDeliveryMethod}<b>{item?.name ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsDeliveries;
