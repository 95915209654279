// prettier-ignore-start
import React from "react";

import { useParams }      from "react-router-dom";
import {Rating, RatingRole, SystemRoles } from "../../../api/methods";
import { T }              from "../../../lang/ru";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import { PATHS }          from "../../../constants/RoutesPaths";
import { useDataRequest } from "../../../hooks/useDataRequest";


const SettingsListsRatingRoles = () => {

    const { rating_id } = useParams ();

    const rating = useDataRequest({
        autoLoad    : true,
        request     : Rating.read,
        requestData : { id: rating_id },
    });

    const formatRole   = (id, item, itemProps) => item?.role?.title;

    const optionsRoles = (success) => SystemRoles.select({}, ({data}) => success(data));

    return (
        <List
            title        = {'Роли рейтинга: ' + (rating.data.title ?? '-')}
            popupPrefix  = {'settingsRatingRoles'}
            getListData  = {(data, s, f) => RatingRole.list({ rating_id }, s, f)}

            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: PATHS.settings.listsRatings},
                ]},
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active', title: 'активен', format: LIF.check   , type: LIT.check , canAddAndEdit: true, default: 0   , align: 'center' },
                {name: 'role_id'  , title: 'роль'   , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true, default: null, align: 'left'   , getOptions: optionsRoles, customFormatHandler: formatRole },
            ]}

            onNew        = {(data, success, fail) => RatingRole.create({...data, rating_id}, success, fail)}
            onEdit       = {RatingRole.update}
            onDelete     = {RatingRole.delete}

            //canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteRatingRole}<b>{item?.rating?.title ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsRatingRoles;
