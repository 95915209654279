// prettier-ignore-start
import React, { useState } from 'react';

import {Col, Row}                   from "styled-bootstrap-grid";
import { Link }                     from "react-router-dom";

import { formatDate }               from '../../../functions/common/common';
import { getApplicationId }         from '../../../functions/getters';
import {dateTranslate, dateTranslateFromApi, isEmpty, isNotEmpty, numberTranslate} from "../../../functions/common";

import { T }                        from '../../../lang/ru';
import { Application, getPath }     from '../../../api/methods';

import { useSaving }                from '../../../hooks/useSaving';
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
import { useAppAccessRightChecks }  from "../../../hooks/useAppAccessRightChecks";

import { green }                    from '../../../constants/Colours';
import { PATHS }                    from "../../../constants/RoutesPaths";

import CustomerApplicationTable     from '../../../components/UI-kit/Table/CustomerApplicationTable';
import { Preloader }                from '../../../components/UI-kit/Preloader/Preloader';
import { SmallInput }               from '../../../components/UI-kit/SmallInput';
import SecondOrderButton            from "../../../components/UI-kit/Buttons/SecondOrderButton";
import { TextareaAutoResize }       from '../../../components/UI-kit/TextareaAutoResize';
import { Empty }                    from '../../../components/Empty';
import { PaymentStatus }            from "../List/components/contents/PaymentStatus";
import { Payer }                    from "../List/components/contents/Payer";
import OrderTableSelect             from '../components/OrderTableSelect';
import FormElementAppText           from "./Components/FormElementAppText";
import OrderInfoDocsGeneration      from "./OrderInfoDocsGeneration";
import OrderInfoPaymentOrder        from './OrderInfoPaymentOrder';
import OrderInfoSignedDocuments     from './OrderInfoSignedDocuments';
import OrderInfoResult              from './OrderInfoResult';


const OrderInfo = ({ isLoading, applicationData, isShowDocuments}) => {

    const userAccess = useUserAccessRightChecks();
    const appAccess  = useAppAccessRightChecks(applicationData?.id);

    const [isShowPaymentsList, setIsShowPaymentsList] = useState(false);

    const {
        id,
        application_date,
        status,
        status_id,
        status_text,
        status_comment,
        form_name,
        category_name,
        payer_id,
        contract_number,
        act_date,
        system_names,
        wishes = '',
        //project_path,
        software_product_name,
        props,
        rooms_count,
        work_deadline,
        deadline,
        //executor_company_id,
        executor_company_name,
        source_name = '',

        delivery_is_needed,
        delivery_track_number,

        executor_work_status_date,
        expert_work_status_date,

        remark                    = '',
        note                      = '',

        //user_id,
        expert_id,
        executor_id,
        executor_rating,

        executor_group_id,
        executor_group_name,
        executor_group_nextDate,

        user_fio,
        expert_fio,
        executor_fio,

        middlename                = '',
        surname                   = '',
        name                      = '',
        name_ip                   = '',
        name_org                  = '',
        price                     = 0,

        promocode_id,
        promocode_name,
        promocode_value,
        promocode_value_type_id,

        contact_name              = '',
        contact_phone             = '',
        contact_email             = '',

        prepayment                = 0,
        // cost          = 0, // стоимость доставки
        arrears                   = 0,
        paid                      = 0,
        payments                  = [],
        final_price               = 0,

        price_details = {
            bonuses_to_spent             : 0,
            bonuses_to_spent_prepayment  : 0,
            bonuses_to_spent_restpayment : 0,
            final_price                  : 0,
            final_price_prepayment       : 0,
            final_price_restpayment      : 0,
            full_price                   : 0,
            full_price_prepayment        : 0,
            full_price_restpayment       : 0,
            promocode_price              : 0,
            promocode_price_prepayment   : 0,
            promocode_price_restpayment  : 0,
        },
        bonuses_to_use,
        bonuses_spent,
    } = applicationData;

    const CommentCommon =
        FormElementAppText({
            canChange : userAccess.isAllowAppDataCommentsCommonEdit,
            title     : 'Общие замечания',
            name      : 'remark',
            value     : remark,
            text      : isEmpty(remark) ? 'нет' : remark,
            id        : applicationData?.id,
        });

    const CommentPrivate = FormElementAppText({
        canChange : userAccess.isAllowAppDataCommentsPrivateEdit,
        title     : 'Замечания администратора',
        name      : 'note',
        value     : note,
        text      : isEmpty(note) ? 'нет' : note,
        id        : applicationData?.id,
    });


    /*
      const updateProjectPath = (e) => {
        const { value } = e.target;

        setIsSuccessUpdatePath(false);
        Application.updateProjectPath({ id, path: value }, () => {
          setIsSuccessUpdatePath(true)
        } );
      };

      const onChangeExecutorCompany = (e) => {
          const { value } = e.target;
          savingExecutorCompany.activate();
          Application.update( {id, executor_company_id: value}, () => {
            savingExecutorCompany.deactivate(true);
            reloadPage();
          }, () => {
            savingExecutorCompany.deactivate(false)
          } );
      }
    */

    const getPromocodeValueText = () => {
        if (! promocode_id) return '';

        switch (promocode_value_type_id) {
            case 0  : return promocode_value+'%';
            case 1  : return promocode_value+' ₽';

            default : return '';
        }
    };

    const outWorkerData = (id, fio, workDate, rating = null) => (
        (id)
            ? <>
                {fio}
                <br />{(workDate)
                    ? ' в работе с '+dateTranslate(workDate)//+' '+timeTranslate(workDate)
                    : ' в работу не принял'
                }
                {isNotEmpty(rating) && <><br /> оценка: {rating}</>}
            </>
            : <Empty />
    );

    const outExecutorDistributionData = (groupId, groupTitle, nextDate) => (
        (groupId)
            ? <><Link className="link" style={{padding: 0}} to={getPath(PATHS.orderInfoDistributionExecutor, {order_id: id})}>поиск...</Link>{' '}(на группе "{groupTitle}")</>
            : null
    );

    const hasPayments = isNotEmpty(payments) && (payments.length > 0);

    const toggleShowPaymentsList = () => setIsShowPaymentsList(! isShowPaymentsList);

    const delivery_is_needed_text = (delivery_is_needed === 1)
        ? 'Требуется...'
        : 'Доставка не требуется';


    if (isLoading || !applicationData.id) return <Preloader />;
    return <Row>
        <Col col="auto">
            <CustomerApplicationTable className="smoothPadding">
                <tbody>
                    <tr>
                        <td className="gray right">Заказ</td>
                        <td>
                            {getApplicationId(contract_number)} от {formatDate(application_date)}
                        </td>
                    </tr>

                    {userAccess.isAllowAppDataActDate && <>
                        <tr>
                            <td className="gray right">Дата акта</td>
                            <td>
                                {isNotEmpty(act_date) ? dateTranslate(act_date) : <Empty text='не задана' />}
                            </td>
                        </tr>
                    </>}

                    <tr>
                        <td className="gray right">{T.status}</td>
                        <td>
                            {/*status_id && status_id+': '*/}
                            {status_text}
                            {status_comment && ' ('+status_comment+')'}<br/>
                        </td>
                    </tr>

                    {userAccess.isAllowAppDataComments && <>
                        <tr>
                            <td className="gray right">Замечания</td>
                            <td>
                              {userAccess.isAllowAppDataCommentsCommon  && 'Общие: ' }{CommentCommon.text }{CommentCommon.form}<br />
                              {userAccess.isAllowAppDataCommentsPrivate && <>Админа:  {CommentPrivate.text}{CommentPrivate.form}</>}
                            </td>
                        </tr>
                    </>}

                    <tr>
                        <td className="gray right">{T.category}</td>
                        <td style={{ whiteSpace: 'normal' }}>{category_name}</td>
                    </tr>

                    <tr>
                        <td className="gray right">Анкета</td>
                        <td style={{ whiteSpace: 'normal' }}>{form_name}</td>
                    </tr>

                    {system_names.map(({ id, name, value }) => (
                        <tr key={id}>
                            <td className="gray right">{name}</td>
                            <td>{isNotEmpty(value) ? value : '–'}</td>
                        </tr>
                    ))}

                    {userAccess.isAllowAppDataRoomsCount && <tr>
                        <td className="gray right">Кол-во помещений/наруж.установок</td>
                        <td style={{ whiteSpace: 'normal' }}>{rooms_count}</td>
                    </tr>}

                    {userAccess.isAllowAppDataDeadlines && <tr>
                        <td className="gray right">Срок по договору / для исполнителя</td>
                        <td style={{ whiteSpace: 'normal' }}>{deadline} / {work_deadline}</td>
                    </tr>}

                    {userAccess.isAllowAppDataExecutorCompany && <tr>
                      <td className="gray right">Организация-исполнитель</td>
                        {/*
                        {(userType === 'ADMIN') && <>
                          <OrderTableSelect
                            placeholder   = "выберите значение..."
                            onChange      = {onChangeExecutorCompany}
                            selectedValue = {executor_company_id}
                            options       = {selectExecutorCompany}
                            style         = {{margin: 0, padding: 5, width: 340, border: '1px solid #eee'}}
                          />
                          {savingExecutorCompany.spinner()}
                        </>}
                        {(userType !== 'ADMIN') && <>{executor_company_name ?? '(не задана)'}</>}
                        /**/}
                      <td>{isEmpty(executor_company_name) ? <Empty text='не задана'/> : executor_company_name }</td>
                    </tr>}

                    {userAccess.isAllowAppDataSoftwareProduct && <tr>
                        <td className="gray right">{T.softwareProduct}</td>
                        <td>{isEmpty(software_product_name) ? <Empty text='не задан'/> : software_product_name }</td>
                    </tr>}

                    {(userAccess.isAllowAppDataProps && props) && props.map((prop, index) => <tr key={index}>
                        <td className="gray right">{prop.title}{prop.is_required ? '*' : ''}</td>
                        <td>
                            <PropTableElement appId={id} prop={prop} />
                        </td>
                    </tr>)}

                    {userAccess.isAllowAppDataContactPerson && <tr>
                        <td className="gray right">{T.deliveryContactPerson}</td>
                        <td>{contact_name}<br />{contact_phone}<br />{contact_email}</td>
                    </tr>}

                    {userAccess.isAllowAppDataWishes && <tr>
                        <td className="gray right">Пожелания</td>
                        <td>{ isEmpty(wishes) ? <Empty /> : wishes }</td>
                    </tr>}

                    <tr><td></td><td></td></tr>

                    {userAccess.isAllowAppDataUser && <tr>
                        <td className="gray right">Заявитель</td>
                        <td>{user_fio}</td>
                    </tr>}

                    {userAccess.isAllowAppDataPayer && <tr>
                        <td className="gray right">Заказчик</td>
                        <td>
                            <Link className="link" style={{padding: 0}} to={
                              getPath(PATHS.orderPayerInfo, {payer_id, order_id: id})
                            }>
                                {Payer().body(applicationData)}
                            </Link>
                        </td>
                    </tr>}

                    {userAccess.isAllowAppDataWorkers && <>
                        <tr>
                            <td className="gray right">Эксперт</td>
                            <td>{outWorkerData(expert_id, expert_fio, expert_work_status_date)}</td>
                        </tr>
                        <tr>
                            <td className="gray right">Исполнитель</td>
                            <td>
                                {(executor_group_id)
                                    ? outExecutorDistributionData(executor_group_id, executor_group_name, executor_group_nextDate)
                                    : outWorkerData(executor_id, executor_fio, executor_work_status_date, executor_rating)
                                }
                            </td>
                        </tr>
                    </>}

                    {(userAccess.isAllowAppDataUser || userAccess.isAllowAppDataPayer || userAccess.isAllowAppDataWorkers) && <tr><td></td><td></td></tr>}

                    {/*const isShowDocuments = (status === 'CUSTOMER' && executor_company_id) || status === 'ADMIN';*/}

                    {/*
                    {userAccess.isAllowAppDataDocsPublished && <tr>
                        <td className="gray right">{T.documents}</td>
                        <td>
                            <OrderInfoDocuments applicationData={applicationData} />
                        </td>
                    </tr>}
                    */}

                    {userAccess.isAllowAppDataDocsGeneration && <tr>
                        <td className="gray right">{T.documents}</td>
                        <td>
                            <OrderInfoDocsGeneration applicationData={applicationData} />
                        </td>
                    </tr>}

                    {userAccess.isAllowAppDataDocsSigned && <tr>
                        <td className="gray right">Подписанные документы</td>
                        <td>
                            <OrderInfoSignedDocuments applicationData={applicationData} />
                        </td>
                    </tr>}

                    {userAccess.isAllowAppDataDocsPaymentOrder && <tr>
                        <td className="gray right">Платежное поручение</td>
                        <td>
                            <OrderInfoPaymentOrder applicationData={applicationData} />
                        </td>
                    </tr>}

                    {appAccess.isAllowAppDataDocsResult && <tr>
                        <td className="gray right">Результат</td>
                        <td>
                            <OrderInfoResult applicationData={applicationData} />
                        </td>
                    </tr>}

                    {(userAccess.isAllowAppDataDocsPublished || userAccess.isAllowAppDataDocsSigned || userAccess.isAllowAppDataDocsPaymentOrder || userAccess.isAllowAppDataDocsResult) && <tr><td></td><td></td></tr>}

                    {userAccess.isAllowAppDataPrice && (<>

                        {userAccess.isAllowAppDataPaymentStatus && <tr>
                            <td className="gray right">Статус оплаты</td>
                            <td>{PaymentStatus().body(applicationData)}</td>
                        </tr>}

                        {userAccess.isAllowAppDataPriceAll && <>
                            {(price_details.full_price !== price_details.final_price) && <tr>
                                <td className="gray right">Полная сумма заявки</td>
                                <td>{numberTranslate(price_details.full_price)} ₽</td>
                            </tr>}

                            {/*userAccess.isAllowUserBonusProgram && - для админа не показывает бонусы*/}
                            {(bonuses_to_use > 0) && <tr>
                                <td className="gray right">Бонусы использованные/запрошенные</td>
                                <td>{price_details.bonuses_to_spent} / {bonuses_to_use}</td>
                            </tr>}

                            {(bonuses_spent > 0) && <tr>
                                <td className="gray right">Сумма использованных бонусов</td>
                                <td>{bonuses_spent}</td>
                            </tr>}

                            {(promocode_name && promocode_value) && <tr>
                                <td className="gray right">Скидка по промокоду {promocode_name} ({getPromocodeValueText()})</td>
                                <td>{numberTranslate(price_details.promocode_price)} ₽</td>
                            </tr>}
                        </>}

                        <tr>
                            <td className="gray right">{userAccess.isAllowAppDataPriceAll ? 'Конечная сумма' : 'Сумма'}</td>
                            <td>{numberTranslate(final_price)} ₽</td>
                        </tr>

                        {userAccess.isAllowAppDataPriceAll && <>
                            <tr>
                                <td className="gray right">Аванс</td>
                                <td>
                                    {prepayment} %
                                    {(prepayment > 0 && price_details.final_price_prepayment > 0) && <> ({numberTranslate(price_details.final_price_prepayment)} ₽)</>}
                                </td>
                            </tr>

                            <tr>
                                <td className="gray right">Оплачено</td>
                                <td>
                                    {numberTranslate(paid)} ₽ {(userAccess.isAllowAppDataPricePaymentsList && hasPayments) &&
                                        <SecondOrderButton
                                            style   = {{ padding: 0 }}
                                            onClick = {toggleShowPaymentsList}
                                        >
                                            {/*isShowPaymentsList ? <>&or; </> : <>&gt; </>*/}
                                            {/*isShowPaymentsList ? <>&#9660;</> : <>&#9658;</>*/}
                                            (оплат: {payments.length})
                                        </SecondOrderButton>
                                    }
                                </td>
                            </tr>

                            {isShowPaymentsList && <tr>
                                <td className="gray"></td>
                                <td>
                                    <PaymentList data={payments} />
                                </td>
                            </tr>}

                            <tr>
                                <td className="gray right">Долг</td>
                                <td>{numberTranslate(arrears)} ₽</td>
                            </tr>
                        </>}

                    </>)}

                    {/*<tr>
                      <td className="gray right">Проект</td>
                      <td>
                        <SmallInput
                            onBlur       = {updateProjectPath}
                            defaultValue = {project_path}
                            placeholder  = "Отсутствует"
                            style        = {{ padding: 10, width: 340, border: '1px solid #eee' }}
                        />
                      </td>
                    </tr>*/}

                    {userAccess.isAllowAppDataDelivery && <>
                        <tr><td></td><td></td></tr>

                        <tr>
                            <td className="gray right">Доставка</td>
                            <td>
                                {((delivery_is_needed === 1) || userAccess.isAllowAppDataDeliveryEdit)
                                    ? <Link
                                        className="link"
                                        style={{minWidth: 200, padding: 0}}
                                        to={getPath(PATHS.orderDelivery, {order_id: id, back_type: 'info'})}
                                    >
                                        {delivery_is_needed_text}
                                    </Link>
                                    : delivery_is_needed_text
                                }
                            </td>
                        </tr>

                        {(delivery_is_needed === 1) && <tr>
                            <td className="gray right">Трек-номер</td>
                            <td>{isEmpty(delivery_track_number) ? <Empty text="отсутствует" /> : delivery_track_number }</td>
                        </tr>}
                    </>}

                    {userAccess.isAllowAppDataSource && <>
                        <tr><td></td><td></td></tr>

                        <tr>
                            <td className="gray right">Источник</td>
                            <td>{isEmpty(source_name) ? <Empty text="отсутствует" /> : source_name }</td>
                        </tr>
                    </>}
                </tbody>
            </CustomerApplicationTable>
        </Col>

        <Col col="auto">
            <CustomerApplicationTable className="smoothPadding">
                <tbody>
                </tbody>
            </CustomerApplicationTable>
        </Col>
    </Row>

};

export const SystemNameCell = ({
    applicationId,
    cellName,
    userAnswer = {},
    setIsUpdating = () => {},
    ...rest
}) => {
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);

    const { elementId, answer } = userAnswer;

    const changeAnswer = (e) => {
        setIsUpdating(true);
        const data = {
            id: applicationId,
            system_name_id: elementId,
            answer: e.target.value,
        };

        Application.updateSystemName(
            data,
            () => {
                setIsSuccessUpdate(true);
                setIsUpdating(false);
            },
            () => {
                setIsSuccessUpdate(false);
                setIsUpdating(false);
            }
        );
    };

    return <td {...rest}>
        <TextareaAutoResize
            placeholder={cellName}
            onBlur={changeAnswer}
            defaultValue={answer}
            style={{
                whiteSpace: 'normal',
                width: 380,
                paddingTop: 14,
                paddingBottom: 20,
                borderColor: isSuccessUpdate ? green : 'transparent',
            }}
        />
    </td>

};

export const PropTableElement = ({ appId, prop, onSave = Application.updateProp }) => {
    const savingProp = useSaving();

    const { type_id } = prop;

    const propTypeText   = 0;
    const propTypeSelect = 1;

    const update = (value) => {
        const data = { app_id: appId, prop_id: prop.id, ...value };

        savingProp.activate();

        Application.updateProp(
            data,
            () => {
                savingProp.deactivate(true);
            },
            () => {
                savingProp.deactivate(false);
            }
        );
    };

    if (type_id === propTypeText) return <>
        <PropTableTextElement prop={prop} updateValue={update} isReadOnly={true}/>
        {savingProp.spinner()}
    </>

    if (type_id === propTypeSelect) return <>
        <PropTableSelectElement prop={prop} updateValue={update} isReadOnly={true} />
        {savingProp.spinner()}
    </>

    return null;
};

export const PropTableTextElement = ({ prop, updateValue, isReadOnly }) => {
    const onBlur = (e) => {
        if (prop.value !== e.target.value)
            updateValue({ value_text: e.target.value });
    };

    return prop.value;

    return <SmallInput
        placeholder  = "введите значение..."
        onBlur       = {onBlur}
        defaultValue = {prop.value}
        style        = {{ margin: 0, padding: 7, width: 340, border: '1px solid #eee' }}
        disabled     = {isReadOnly}
    />

};

export const PropTableSelectElement = ({ prop, updateValue, isReadOnly }) => {
    const { options, value_id } = prop;

    const selectOptions = options.map(({ id, title }) => ({
        value: id,
        label: title,
    }));

    const selected = options.find(({ id, title }) => ( id === value_id ));

    return selected?.title ?? '';

    const onChange = (e) => {
        updateValue({ option_id: e.target.value });
    };

    return <OrderTableSelect
        placeholder   = "выберите значение..."
        onChange      = {onChange}
        selectedValue = {value_id}
        options       = {selectOptions}
        style         = {{ margin: 0, padding: 5, width: 340, border: '1px solid #eee' }}
        disabled      = {isReadOnly}
    />

};

export const PaymentList = ({ data }) => (
    <CustomerApplicationTable className="smoothPadding" style={{minWidth: '600px'}}>
        <tbody>
            {data.map( ({ id, payment_date, payment_sum, comment }) =>
                <tr key={id}>
                    <td className="gray">{dateTranslateFromApi(payment_date)}</td>
                    <td>{numberTranslate(payment_sum)} ₽</td>
                    <td><i>{comment}</i></td>
                </tr>
            )}
        </tbody>
    </CustomerApplicationTable>
  );

export default OrderInfo;
